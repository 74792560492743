YP.EventStyleForm = function() {
    return {
        init: function() {
            $("#eventStyleForm_body_background_color").spectrum({
                preferredFormat: "hex",
                clickoutFiresChange: true,
                showInitial: true,
                showInput: true,
                chooseText: "Choisir",
                cancelText: "Annuler",
                showPalette: true,
                showSelectionPalette: true,
                palette: [],
                localStorageKey: "yurplan.spectrum",
                move: function(color) {
                    $('body').css('background-color', color.toHexString());
                    YP.EventStyleForm().reinitializeCarousel();
                },
                change: function(color) {
                    $('body').css('background-color', color.toHexString());
                    YP.EventStyleForm().reinitializeCarousel();
                }
            });
            $("#eventStyleForm_body_text_color").spectrum({
                preferredFormat: "hex",
                clickoutFiresChange: true,
                showInitial: true,
                showInput: true,
                chooseText: "Choisir",
                cancelText: "Annuler",
                showPalette: true,
                showSelectionPalette: true,
                palette: [],
                localStorageKey: "yurplan.spectrum",
                move: function(color) {
                    $('body').css('color', color.toHexString());
                    YP.EventStyleForm().reinitializeCarousel();
                },
                change: function(color) {
                    $('body').css('color', color.toHexString());
                    YP.EventStyleForm().reinitializeCarousel();
                }
            });
            $("#eventStyleForm_block_title_text_color").spectrum({
                preferredFormat: "hex",
                clickoutFiresChange: true,
                showInitial: true,
                showInput: true,
                chooseText: "Choisir",
                cancelText: "Annuler",
                showPalette: true,
                showSelectionPalette: true,
                palette: [],
                localStorageKey: "yurplan.spectrum",
                move: function(color) {
                    $('.block .title').css('color', color.toHexString());
                    YP.EventStyleForm().reinitializeCarousel();
                },
                change: function(color) {
                    $('.block .title').css('color', color.toHexString());
                    YP.EventStyleForm().reinitializeCarousel();
                }
            });
            $("#eventStyleForm_block_title_background_color").spectrum({
                preferredFormat: "hex",
                clickoutFiresChange: true,
                showInitial: true,
                showInput: true,
                chooseText: "Choisir",
                cancelText: "Annuler",
                showPalette: true,
                showSelectionPalette: true,
                palette: [],
                localStorageKey: "yurplan.spectrum",
                move: function(color) {
                    $('.block .title').css('background-color', color.toHexString());
                    YP.EventStyleForm().reinitializeCarousel();
                },
                change: function(color) {
                    $('.block .title').css('background-color', color.toHexString());
                    YP.EventStyleForm().reinitializeCarousel();
                }
            });
            $("#eventStyleForm_block_background_color").spectrum({
                preferredFormat: "hex",
                clickoutFiresChange: true,
                showInitial: true,
                showInput: true,
                chooseText: "Choisir",
                cancelText: "Annuler",
                showPalette: true,
                showSelectionPalette: true,
                palette: [],
                localStorageKey: "yurplan.spectrum",
                move: function(color) {
                    $('footer.event .content').css('background-color', color.toHexString());
                    $('.block .content').css('background-color', color.toHexString());
                    $('.block .nav').css('background-color', color.toHexString());
                    YP.EventStyleForm().reinitializeCarousel();
                },
                change: function(color) {
                    $('.block .content').css('background-color', color.toHexString());
                    YP.EventStyleForm().reinitializeCarousel();
                }
            });
            $("#eventStyleForm_block_border_color").spectrum({
                preferredFormat: "hex",
                clickoutFiresChange: true,
                showInitial: true,
                showInput: true,
                chooseText: "Choisir",
                cancelText: "Annuler",
                showPalette: true,
                showSelectionPalette: true,
                palette: [],
                localStorageKey: "yurplan.spectrum",
                move: function(color) {
                    $('.block .content').css('border-color', color.toHexString());
                    $('.block .title').css('border-color', color.toHexString());
                    $('footer.event .content').css('border-color', color.toHexString());
                    YP.EventStyleForm().reinitializeCarousel();
                },
                change: function(color) {
                    $('.block .content').css('border-color', color.toHexString());
                    $('.block .title').css('border-color', color.toHexString());
                    YP.EventStyleForm().reinitializeCarousel();
                }
            });
            $("#eventStyleForm_link_color").spectrum({
                preferredFormat: "hex",
                clickoutFiresChange: true,
                showInitial: true,
                showInput: true,
                chooseText: "Choisir",
                cancelText: "Annuler",
                showPalette: true,
                showSelectionPalette: true,
                palette: [],
                localStorageKey: "yurplan.spectrum",
                move: function(color) {
                    $('.event-page a:not(.btn):not(.title):not(.fileinput)').css('color', color.toHexString());
                    $('footer a:not(.btn)').css('color', color.toHexString());
                    $('.event-page .btn-primary').css('background-color', color.toHexString());
                    YP.EventStyleForm().reinitializeCarousel();
                },
                change: function(color) {
                    $('.event-page a:not(.btn):not(.title):not(.fileinput)').css('color', color.toHexString());
                    $('footer a:not(.btn)').css('color', color.toHexString());
                    $('.event-page .btn-primary').css('background-color', color.toHexString());
                    YP.EventStyleForm().reinitializeCarousel();
                }
            });
            $("#eventStyleForm_cover_text_color").spectrum({
                preferredFormat: "hex",
                clickoutFiresChange: true,
                showInitial: true,
                showInput: true,
                chooseText: "Choisir",
                cancelText: "Annuler",
                showPalette: true,
                showSelectionPalette: true,
                palette: [],
                localStorageKey: "yurplan.spectrum",
                move: function(color) {
                    $('.cover .cover-title h1 a span').css('color', color.toHexString());
                    YP.EventStyleForm().reinitializeCarousel();
                },
                change: function(color) {
                    $('.cover .cover-title h1 a span').css('color', color.toHexString());
                    YP.EventStyleForm().reinitializeCarousel();
                }
            });
            $("#eventStyleForm_btn_primary").spectrum({
                preferredFormat: "hex",
                clickoutFiresChange: true,
                showInitial: true,
                showInput: true,
                chooseText: "Choisir",
                cancelText: "Annuler",
                showPalette: true,
                showSelectionPalette: true,
                palette: [],
                localStorageKey: "yurplan.spectrum",
                move: function(color) {
                    $('.event-page .btn-success').css('background-color', color.toHexString());
                    YP.EventStyleForm().reinitializeCarousel();
                },
                change: function(color) {
                    $('.event-page .btn-success').css('background-color', color.toHexString());
                    YP.EventStyleForm().reinitializeCarousel();
                }
            });
            $("#eventStyleForm_body_background_image").on('input', function(e) {
                urlImg = $(this).val();
                input = $(this);
                if (urlImg.length > 10 && urlImg.substring(0, 4) == "http") {
                    $("<img>", {
                        src: urlImg,
                        error: function() {
                            $('body').removeAttr('style');
                            input.parent().removeClass('has-success').addClass('has-warning');
                        },
                        load: function() {
                            input.parent().removeClass('has-warning').addClass('has-success');
                            $('body').css('background', 'top center no-repeat fixed').css('background-image', 'url(' + urlImg + ')').css('background-size', 'cover');
                        }
                    });
                } else if (urlImg.length > 4 && urlImg.substring(0, 4) != "http") {
                    input.parent().removeClass('has-success').addClass('has-warning');
                } else if (urlImg.length == 0) {
                    $('body').css('background', 'auto').css('background-image', 'none').css('background-size', 'auto');
                }
            });
            $(".owl-carousel").owlCarousel({
                'items': 3
            });
            $('.carousel-item .event-template').on('click', function(event) {
                var $this = $(this);
                $('.owl-carousel .carousel-selector').hide();
                $('.owl-carousel .event-template').css('opacity', 0.6);
                $this.css('opacity', 1);
                $this.parent().find('.carousel-selector').show();
                $style = $this.data('style');
                $.each($style, function(idx, rec) {
                    switch (idx) {
                        case 'body':
                            $("#eventStyleForm_body_background_color").spectrum('set', rec['background-color']);
                            $('body').css('background-color', rec['background-color']);
                            $("#eventStyleForm_body_text_color").spectrum('set', rec['color']);
                            $('body').css('color', rec['color']);
                        case '.cover .cover-title h1 a':
                            $("#eventStyleForm_cover_text_color").spectrum('set', rec['color']);
                            $('.cover .cover-title h1 a span').css('color', rec['color']);
                        case '.event-page a:not(.btn)':
                            $("#eventStyleForm_link_color").spectrum('set', rec['color']);
                            $('.event-page a:not(.btn)').css('color', rec['color']);
                            $('footer a:not(.btn)').css('color', rec['color']);
                            $('.event-page .btn-primary').css('background-color', rec['color']);
                        case '.block .title':
                            $("#eventStyleForm_block_title_text_color").spectrum('set', rec['color']);
                            $("#eventStyleForm_block_title_background_color").spectrum('set', rec['background-color']);
                            $("#eventStyleForm_block_border_color").spectrum('set', rec['border-color']);
                            $('.block .title').css('color', rec['color']).css('border-color', rec['border-color']).css('background-color', rec['background-color']);
                        case '.block .content':
                            $("#eventStyleForm_block_background_color").spectrum('set', rec['background-color']);
                            $('.block .content').css('background-color', rec['background-color']).css('border-color', rec['border-color']);
                    }
                });
            });
        },
        reinitializeCarousel: function() {
            $('.owl-carousel .carousel-selector').hide();
            $('.owl-carousel .event-template').css('opacity', 1);
        },
    }
};
window.addEventListener("load", function() {
    if ($('#eventStyleForm').length > 0) {
        YP.EventStyleForm().init();
    }
    if ($('.alert.alert-save-template').length > 0) {
        YP.ModalManager().setPreCallSuccess(function() {
            $('.alert.alert-save-template').remove()
        });
    }
});