const { $, YP } = window;

window.YP.StoreWorkshopsManager = () => {
    // Configuration
    const MAX_WORKSHOP_NAME_LENGTH = 25;

    // Selectors
    const SELECTORS = {
        mainContainer: '#productForms, #store_workshop_container',
        mainForm: '#productForms',
        mainSubmitButton: '.js-btn_confirm',
        workshopTag: '.workshop-tag',
        workshopRemove: '.js-workshop-remove',
        currentWorkshopsCartList: '.enable .cart-list-ticket-products',
        workshopListInput: '.js-workshop-data input',
        workshopDate: '.js-workshop-date',
        workshopDateContainer: '.js-workshop-date-container',
        workshopSessionContainer: '.js-workshop-session-container',
        cartTotal: '.js-cart-total',
    };

    // State variables
    let workshopsAreLoading = false;
    const REMOTE_CART = [];
    $(SELECTORS.currentWorkshopsCartList).find('li').each((i, domCartItem) => {
        const productType = window.JSON_PRODUCTS.find((product) => product.id === $(domCartItem).data('id'));
        if (!productType) return; // Product not found
        REMOTE_CART.push(productType);
    });
    let cart = [...REMOTE_CART];

    const maxQtyWorkshop = $('#store_workshop_list').data('maxqty');
    const minQtyWorkshop = $('#store_workshop_list').data('minqty');
    const freeWorkshopsCanBeCanceled = $('#store_workshop_list').data('free-workshops-can-be-canceled');

    return {
        init() {
            if (!$(SELECTORS.mainContainer).length) return;

            const self = this;

            // Send a request when clicking on a workshop category tag
            $(SELECTORS.mainContainer).on('click', SELECTORS.workshopTag, function handleToggleTag(e) {
                e.preventDefault();
                if (workshopsAreLoading) return;
                workshopsAreLoading = true;
                self.toggleTag($(this));
                self.refreshProductWorkshopForm();
            });

            // Remove product from cart when clicking on the remove button
            $(SELECTORS.currentWorkshopsCartList).on('click', SELECTORS.workshopRemove, function handleRemoveFromCart(e) {
                e.preventDefault();
                const typeProductId = $(this).data('id');
                const typeProduct = window.JSON_PRODUCTS.find((product) => (
                    product.id === typeProductId
                ));
                self.removeProductFromCart(typeProduct);
                self.handleCartChange();
            });

            // Handle add/remove product event
            $(SELECTORS.mainForm).on('change', SELECTORS.workshopListInput, function handleProductChange() {
                const productTypeId = Number.parseInt($(this).parents('.js-workshop-data').data('type'), 10);
                const productType = window.JSON_PRODUCTS.find((product) => (
                    product.id === productTypeId
                ));
                if (!productType) return; // Product not found
                if (this.checked) {
                    self.addProductToCart(productType);
                } else {
                    self.removeProductFromCart(productType);
                }
                self.handleCartChange();
            });

            // Handle the collapse event of the workshop descriptions
            let lastMaxHeight = null;
            $(SELECTORS.mainForm).on('click', '.js-get-info', function handleDescriptionCollapse() {
                const parentDescription = $(this).siblings('.description');
                if (parentDescription.css('overflow-y') === 'hidden') {
                    parentDescription.css('overflow-y', 'visible');
                    lastMaxHeight = parentDescription.css('max-height');
                    parentDescription.css('max-height', '100%');
                    $(this).text('Fermer');
                } else {
                    parentDescription.css('overflow-y', 'hidden');
                    parentDescription.css('max-height', lastMaxHeight);
                    $(this).text('Plus de détails');
                }
            });

            this.initFixedCartBehavior();
            this.initTagsHover();

            // Prevent multiple form submit
            $(SELECTORS.mainForm).on('submit', () => {
                $(SELECTORS.mainSubmitButton).prop('disabled', true);
            });

            // /!\ Hack to handle invalid HTML form/input/submit usage /!\
            // The .js-btn_confirm button is not inside the form but only in the widget DOM
            // This event handler submit the related form
            $(SELECTORS.mainSubmitButton).on('click', function handleSubmitOutsideOfTheForm() {
                const isInForm = $(this).parents(SELECTORS.mainForm).length > 0;
                if (isInForm) return;
                $(SELECTORS.mainForm).submit();
            });
            if ($(SELECTORS.mainForm).length > 0) {
                this.handleCartChange();
                this.updateInputs();
            }
        },
        addProductToCart(productType) {
            const productAlreadyInCart = cart.some((product) => product.id === productType.id);
            if (productAlreadyInCart) {
                cart = cart.map((product) => {
                    if (product.id !== productType.id) return product;
                    return {
                        ...product,
                        toCancel: false,
                    };
                });
                return;
            }

            // Add product to JS Cart
            cart = cart.concat([{
                ...productType,
                toCancel: false,
            }]);
        },
        removeProductFromCart(productType) {
            const productInRemoteCart = REMOTE_CART.some((product) => (
                product.id === productType.id
            ));
            if (productInRemoteCart) { // Mark as remove
                cart = cart.map((product) => {
                    if (product.id !== productType.id) return product;
                    return {
                        ...product,
                        toCancel: true,
                    };
                });
            } else { // Remove product from JS Cart
                cart = cart.filter((product) => product.id !== productType.id);
            }

            $(`#select_type_product_workshop_products_${productType.id}`).prop('checked', false);
        },
        handleCartChange() {
            this.updateWorkshopsConflicts();
            this.updateDOMCart();
            this.updateTotalCart();
            this.updateActionCart();
            this.handleQuantityAvailable();
        },
        updateDOMCart() {
            $(SELECTORS.currentWorkshopsCartList).empty();
            const productsGroupedByDay = {};
            cart.forEach((productType) => {
                const date = new Date(productType.begin * 1000);
                const options = { weekday: 'long', day: 'numeric', month: 'long' };
                const day = date.toLocaleDateString('fr-FR', options);
                const capitalizedDay = day.charAt(0).toUpperCase() + day.slice(1);
                if (!productsGroupedByDay[capitalizedDay]) {
                    productsGroupedByDay[capitalizedDay] = [];
                }
                productsGroupedByDay[capitalizedDay].push(productType);
            });
            Object.keys(productsGroupedByDay).forEach((day) => {
                $(SELECTORS.currentWorkshopsCartList).append($(`
                    <li class="cart-list-date-header mt-2 mb-1">
                        <p class="c-type-body--2 u-color__grey--50">
                            ${day}
                        </p>
                    </li>`));
                productsGroupedByDay[day].sort((a, b) => a - b).forEach((productType) => {
                    const productTemplate = $(this.getTypeProductTemplate(productType));
                    $(SELECTORS.currentWorkshopsCartList).append(productTemplate);
                });
            });
        },
        handleQuantityAvailable() {
            const countCurrentProduct = cart.filter((product) => !product.toCancel).length;
            if (Number.isInteger(minQtyWorkshop)) {
                if (countCurrentProduct < minQtyWorkshop) {
                    $(SELECTORS.mainSubmitButton).prop('disabled', true);
                } else {
                    $(SELECTORS.mainSubmitButton).prop('disabled', false);
                }
            }

            if (Number.isInteger(maxQtyWorkshop)) {
                if (countCurrentProduct >= maxQtyWorkshop) {
                    $(`${SELECTORS.mainForm} label:not(.u-workshop_disabled) input[type='checkbox']:not(:checked)`).prop('disabled', true);
                } else {
                    $(`${SELECTORS.mainForm} label:not(.u-workshop_disabled) input[type='checkbox']:not(:checked)`).prop('disabled', false);
                }
            }
        },
        getTypeProductTemplate(_productType) {
            const productType = _productType;
            const productInRemoteCart = REMOTE_CART.some((product) => (
                product.id === productType.id
            ));
            if (productType.name.length >= MAX_WORKSHOP_NAME_LENGTH) {
                productType.name = `${productType.name.substring(0, 25).split(' ').slice(0, -1).join(' ')}...`;
            }

            const beginTime = new Date(productType.begin * 1000).toLocaleTimeString('fr-FR', { hour: 'numeric', minute: 'numeric' });
            const endTime = new Date(productType.end * 1000).toLocaleTimeString('fr-FR', { hour: 'numeric', minute: 'numeric' });

            return `
            <li
                class="cart-list-product c-list c-list--stacked mb-2
                    ${productType.toCancel ? 'to-cancel' : ''}
                    ${(productType.hasConflict ?? false) ? 'warning' : ''}
                "
                data-id=${productType.id}
                data-booked=0
            >
                <div class="c-list__item">
                    ${productType.workshop_picture ? `
                        <img src="${productType.workshop_picture}" class="c-icon"/>
                    ` : ''}
                        <div class="c-list__item-text px-2">
                            ${beginTime} - ${endTime}
                            <div class="c-type-body--2">${productType.name}</div>
                        </div>
                    <div class="c-list__item-action">
                        ${productType.locked !== true && !window.HIDE_PRICE ? `
                            <div class="c-type-body--2 u-color__grey--60 mr-2">
                                ${productType.amount === 0 ? 'Inclus' : productType.amount.toFixed(2)}
                            </div>
                        ` : ''}
                        ${!productType.toCancel && (freeWorkshopsCanBeCanceled && productType.amount === 0) || !productInRemoteCart ? `
                            <a class="c-btn c-btn--small mr-0 c-btn--tertiary c-btn--square js-workshop-remove" href="#" data-id=${productType.id}>
                                <i class="yp_font yp_font_trash-2" style="position: absolute;" aria-hidden="true"></i>
                            </a>
                        ` : ''}
                    </div>
                </div>
            </li>`;
        },
        initFixedCartBehavior() {
            if (!$('.fixedElement').length) return;

            const rightColumnOffset = $('.fixedElement').offset().top;

            $(window).scroll(function handleScroll() {
                const leftColumn = $('.event-page .u-col-8, .js-scroll-col-atelier');
                const rightColumnHeight = $('.fixedElement').outerHeight();
                const windowHeight = $(window).height();
                const scrollTop = $(this).scrollTop();
                const spaceBottom = 62;
                const stickedOffset = (rightColumnOffset + rightColumnHeight)
                    - windowHeight + spaceBottom;
                leftColumn.css('minHeight', rightColumnHeight);
                if (rightColumnHeight > windowHeight - spaceBottom - rightColumnOffset) {
                    $('.fixedElement').removeClass('stickedtop');
                    if (scrollTop > stickedOffset) {
                        $('.fixedElement').addClass('sticked');
                    } else {
                        $('.fixedElement').removeClass('sticked');
                    }
                } else {
                    $('.fixedElement').removeClass('sticked');
                    if (scrollTop > 0) {
                        $('.fixedElement').addClass('stickedtop');
                    } else {
                        $('.fixedElement').addClass('stickedtop');
                    }
                }
            });
        },
        initTagsHover() {
            $('.workshop-filter-option .workshop-tag').hover(
                function handleHoverStart() {
                    $(this).css({
                        'background-color': $(this).data('font'),
                        color: $(this).data('background'),
                        border: `1px solid ${$(this).data('background')}`,
                    });
                },
                function handleHoverStop() {
                    if ($(this).hasClass('active')) {
                        $(this).css({
                            'background-color': $(this).data('background'),
                            color: $(this).data('font'),
                            border: '1px solid transparent',
                        });
                    } else {
                        $(this).css({
                            'background-color': 'transparent',
                            color: '#9A9A9A',
                            border: '1px solid #9A9A9A',
                        });
                    }
                },
            );
        },
        toggleTag(tag) {
            if (tag.hasClass('active')) {
                tag.css({
                    'background-color': 'transparent',
                    color: '#9A9A9A',
                    border: '1px solid #9A9A9A',
                });
            } else {
                tag.css({
                    'background-color': tag.data('background'),
                    color: tag.data('font'),
                    border: '1px solid transparent',
                });
            }
            tag.toggleClass('active');
        },
        updateTotalCart() {
            const total = cart.reduce((currentTotal, product) => {
                if (product.toCancel !== true && product.locked !== true) {
                    return currentTotal + product.amount;
                }
                return currentTotal;
            }, window.CART_TOTAL);
            $(SELECTORS.cartTotal).html(total.toFixed(2));
        },
        updateActionCart() {
            const hasWorkshopsConflicts = this.hasWorkshopsConflicts();
            // Selected products or no product available
            const isValid = $(SELECTORS.currentWorkshopsCartList).find('li.cart-list-product:not(.disabled)').length > 0
            || $(SELECTORS.currentWorkshopsCartList).find('li.to-cancel').length > 0
            || $(SELECTORS.workshopListInput).not(':disabled').is(':checked')
            || (
                $('.list-products tbody tr').length
                && $('.list-products tbody tr:not(.u-workshop_disabled)').length <= 0
            );
            if ((hasWorkshopsConflicts && !window.CAN_BOOK_SAME_WORKSHOP_SLOT) || !isValid) {
                // Prevent skip button when workshop is mandatory
                if (minQtyWorkshop <= 0) {
                    $('.js-btn_next').show();
                } else {
                    $('.js-btn_next').hide();
                }
                $(SELECTORS.mainSubmitButton).hide();
            } else {
                $('.js-btn_next').hide();
                $(SELECTORS.mainSubmitButton).show();
            }
            $('.warning-date')[hasWorkshopsConflicts ? 'show' : 'hide']();
        },
        hasWorkshopsConflicts() {
            return cart.some((product) => product.hasConflict ?? false);
        },
        updateWorkshopsConflicts() {
            cart = cart.map((product) => ({
                ...product,
                // Source: https://stackoverflow.com/a/325964
                hasConflict: product.toCancel === false && cart.some((nestedProduct) => (
                    nestedProduct.toCancel === false
                     && nestedProduct.id !== product.id
                     && (product.begin < nestedProduct.end)
                     && (product.end > nestedProduct.begin)
                )),
            }));
        },
        updateInputs() {
            const productIds = cart.map((product) => product.id);
            // Check the retrieved inputs accordingly to the local cart data
            $('#store_workshop_list').find(`input[value="${productIds.join('"],[value="')}"]`)
                .prop('checked', true);
        },
        refreshProductWorkshopForm() {
            const self = this;

            const tagIds = $('.workshop-tag.active').map(function extractTagId() {
                return $(this).data('id');
            }).get();

            const url = $('.workshop-header').data('refresh');
            $('#store_workshop_list').css('opacity', 0.5);
            $.ajax({
                type: 'GET',
                url,
                dataType: 'json',
                data: {
                    filterTag: tagIds,
                },
                success(data) {
                    $('#store_workshop_list').html(data.view);
                    self.updateInputs();
                    workshopsAreLoading = false;
                    $('#store_workshop_list').css('opacity', 1);
                },
            });
        },
    };
};

window.addEventListener('load', () => {
    YP.StoreWorkshopsManager().init();
});
