YP.ProfileNotificationsManager = function() {
    var notificationsList = $('#notifications-list')

    return {
        init: function(){

            YP.ProfileNotificationsManager().refreshNotificationTitle();
            $(".notifications .dropdown-toggle, .notifications a.menu").on('click', this.showNotif);

            if($('ul li .btn.ajaxOnList').length > 0) {
                var tab = $('li.active a b', 'ul.nav-tabs');
                $('ul li .btn.ajaxOnList').click(function(e){
                      e.preventDefault();
                      var el = $(this),
                          parent = el.parents('li');
                      $('.btn', parent).remove();
                      $.post($(this).attr('href'), function(data, textStatus, jqXHR){
                          $('.item-right', parent).html('<span style="line-height: 30px">' + data.message + '</span>');
                          tab.html((parseFloat(tab.html()) - 1 ));
                     });
                      return false;
                });
            }
        },
        refreshNotificationTitle: function() {
            title = ($('title', 'head').html() ?? '').replace(/\(\d\)\s/g, '');
            var count = $('.notifications', '.navbar-inner').data('count');
            if(count > 0) {
                $('title', 'head').html("("+count+") " + title);
            } else {
                $('title', 'head').html(title);
            }
        },
        closeNotif: function() {
            $('.dropdown-toggle, a.menu').parent("li").removeClass("open");
            return true;
        },
        showNotif: function() {
            var el = $(this),
            parent = el.parent("li"),
            isOpen = el.parent('li').hasClass('open');

            if(parent.hasClass('notifications')) {
                    $.get(parent.data('url'), function(data) {
                        notificationsList.html(data);
                        $.get(parent.data('read-url'), function(){
                            $('.notif', parent).find('span').html('').end().removeClass('notif-on').addClass('notif-off');
                            $('.notifications', '.nav-inner').attr('data-count', 0);
                            YP.ProfileNotificationsManager().refreshNotificationTitle();
                        });
                });
            }

        }
    }
};

window.addEventListener("load", function(){
    YP.ProfileNotificationsManager().init();
});
