YP.PictureManager = function() {
    return {
        init: function() {
            $('form.upload #fileupload').fileupload({
                dataType: 'json',
                url: $('form.upload').data('url'),
                add: function (e, data) {
                    data.submit();
                },
                send: function (e, data) {
                    $('.refresh', 'form.upload').show();
                    YP.PictureManager().initZone();
                },
                done: function (e, data) {
                    if (data.result) {
                        YP.PictureManager().launchPreview(data.result);
                    }
                }
            });
        },
        updateCoords: function (c) {
            jQuery('form.upload #picture_coord_x').val(c.x);
            jQuery('form.upload #picture_coord_y').val(c.y);
            jQuery('form.upload #picture_width').val(c.w);
            jQuery('form.upload #picture_height').val(c.h);
        },
        showPreview: function(coords)
        {
            var cropSizeW = 168;
            var cropSizeH = 168;
            cropZone = $('.crop-zone img', 'body');
            var imgW = cropZone.width();
            var imgH = cropZone.height();
            var rx = cropSizeW / coords.w;
            var ry = cropSizeH / coords.h;

            $('form.upload #preview').css({
                width: Math.round(rx * imgW) + 'px',
                height: Math.round(ry * imgH) + 'px',
                marginLeft: '-' + Math.round(rx * coords.x) + 'px',
                marginTop: '-' + Math.round(ry * coords.y) + 'px'
            });
            YP.PictureManager().updateCoords(coords);
            if (cropSizeW < 168) {
                $('form.upload .jcrop-handle').hide();
            }
        },
        launchPreview: function (data) {
            cropSizeW = 168;
            cropSizeH = 168;
            $('.refresh', 'form.upload').hide();
            if (!data.error) {
                $('body form.upload .crop-zone').html($('<img src="/uploads/preview/'+data.name +'"/>').css('width', data.width +'px').css('height', data.height+'px'));
                $('body .preview').html($('<img src="/uploads/preview/'+data.name +'" id="preview"/>'));
                $('form.upload #picture_name').val(data.name);
                $('form.upload .preview').parent().show();
                $('form.upload .actions').show();
                $('body form.upload .miniature-label').show();
                $('body form.upload .crop-zone img').Jcrop({
                    setSelect: [0, 0, cropSizeW, cropSizeH],
                    aspectRatio: 1,
                    onChange: YP.PictureManager().showPreview,
                    onSelect: YP.PictureManager().showPreview
                });
            }
            else {
                $('body .crop-zone').html('');
                for (var i in data.error) {
                    $('<p/>').css('color','red').attr('class','error').text(data.error[i]).appendTo($('body .crop-zone'));
                }
            }
        },
        initZone: function () {
            $('body form.upload .crop-zone').text('');
            $('body form.upload .preview').text('');
            $('body form.upload .crop-zone').text('');
            $('body form.upload .miniature-label').hide();
            $('body form.upload .miniature-label').css('margin-top', '168px');
            $('form.upload .jcrop-handle').show();
        }
    }

};