/* eslint-disable */
function isIE() {
    var myNav = navigator.userAgent.toLowerCase();
    return (myNav.indexOf('msie') != -1) ? parseInt(myNav.split('msie')[1]) : false;
}

function array_unique(ar){
    if (ar.length && typeof ar !== 'string') {
        var sorter = {};
        var out = [];
        for (var i=0,j=ar.length;i<j;i++) {
            if (!sorter[ar[i] + typeof ar[i]]) {
                out.push(ar[i]);
                sorter[ar[i] + typeof ar[i]] = true;
            }
        }
    }
    return out || ar;
}

window.constructUrl = function constructUrl(path) {
    regExp = /^\/(\w+\_(dev|stage)\.php)/g;
    match = regExp.exec(location.pathname);
    if(match) {
        return location.origin + match[0] + '/' + path;
    } else {
        return location.origin + '/' + path;
    }
}

window.php_urlencode = function php_urlencode (str) {
    str = escape(str);
    return str.replace(/[*+\/@]|%20/g, function (s) {
        switch (s) {
            case "*": s = "%2A"; break;
            case "+": s = "%2B"; break;
            case "/": s = "%2F"; break;
            case "@": s = "%40"; break;
            case "%20": s = "+"; break;
        }
        return s;
    });
}

jQuery.fn.center = function () {
    this.css("position","absolute");
    this.css("top", (($(window).height() - this.outerHeight()) / 2) + $(window).scrollTop() + "px");
    this.css("left", (($(window).width() - this.outerWidth()) / 2) + $(window).scrollLeft() + "px");
    return this;
}

String.prototype.format = function() {
  var str = this;
  for (var i = 0; i < arguments.length; i++) {
    var reg = new RegExp("\\{" + i + "\\}", "gm");
    str = str.replace(reg, arguments[i]);
  }
  return str;
}

function ajaxErrorHandler(xmlHttpRequest, textStatus, errorThrown) {
     if(xmlHttpRequest.readyState == 0 || xmlHttpRequest.status == 0) {
         return;
     }else {
         alert('Une erreur vient de se produire.');
     }
}

window.resizeDescriptionIframe = function resizeDescriptionIframe(obj){
    obj.style.height = 0;
    obj.style.height = obj.contentWindow.document.body.scrollHeight + 'px';
}

window.addEventListener("load", function() {
    if (isIE () && isIE () < 11) {
        $('.upgradeIE').css('display','block');
    } else {
        $('.upgradeIE').css('display','none');
    }

    $('.dropdown-toggle').dropdown();
    $('[data-toggle="popover"]').popover({
    trigger : "hover",
    placement: "bottom"
    });
    window.initDatePicker();

    //remove alert box
    $('.alert a.remove').on('click', function(e){
        e.preventDefault();
        $(this).parents().eq(1).remove();
    });

    if($.browser.msie == true && $.browser.version <= 10.0) {
        var ieDiv = $('.ie-div');
        ieDiv.show();
        $('.ie-div a').on('click', function(){
            ieDiv.hide();
        });
    }
});
