function resizeCarouselPicture() {
  if($('.carousel').length > 0) {
    var imgHtml = $('#myCarousel a img');
    var img = new Image();
    img.src = imgHtml.attr('src');

    img.onload = function() {
      var width = img.width,
          height = img.height,
          totalWidth = 375 + width,
          totalWidth = 375 + width,
          coef = 1;

      if (totalWidth > $(window).width() - 100) {
        var coef =  totalWidth / ($(window).width() - 100);
        width = width / coef;
        height = height / coef;
      }
      if (height > $(window).height() - 100) {
        var coef =  height / ($(window).height() - 100);
        width = width / coef;
        height = height / coef;
      }

      imgHtml.css('width', width);
      imgHtml.css('height', height);
      finalWidth = width < 500 ? 500 : width;
      finalHeight = height < 500 ? 500 : height;
      totalWidth = Math.ceil(finalWidth + 375);

      $('.popup .container.content').css('width', totalWidth);
      $('.popup .carousel .picture').css('height', finalHeight);
      $('.popup .carousel .picture').css('width', finalWidth);
      // $('.popup .carousel .waiting-pictures').css('width', finalWidth);
      // $('.popup .carousel .waiting-pictures').css('height', finalHeight);
      $('.popup .carousel .messages').css('max-height', finalHeight - 166);
      $('.carousel .picture-curent').attr('data-ratio', coef);
    }
  };
}

function changePicture(link)
  {
    var $el = $(link),
        href = $el.attr('href'),
        carousel = $('.carousel');

    $('.waiting-pictures').fadeTo("fast", 0.2);
    $('.waiting-pictures').css("z-index", 99);

    $.ajax({
        type: 'GET',
        url: href,
        success: function(data){
            carousel.html(data);
            // $('.carousel .picture').css('height', oHeight);
            // $('.carousel .picture').css('width', oWidth);
            // $('.carousel .waiting-pictures').css('width', oWidth);
            // $('.carousel .waiting-pictures').css('height', oHeight);
            // resizeCarouselPicture();
            $('.waiting-pictures').fadeTo("fast", 0);
            $('.waiting-pictures').css("z-index", 0);
        },
        error: function(data) {
        }
    });
  }

$('#main-modal').on('show.bs.modal', function (e) {
  if($('.carousel').length) {
    window.scrollTo(0, 0);
    $('.modal-body').on('click', '.picture-curent img', function(e){
      e.preventDefault();
      changePicture($('.picture-next'));
    });
    $('.modal-body').on('click', '.picture-prev', function(e){
      e.preventDefault();
      changePicture($(this));
    });
    $('.modal-body').on('click', '.picture-next', function(e){
      e.preventDefault();
      changePicture($(this));
    });

    //On bind les touches left/right pour naviguer dans le carousel
    $(document).keydown(function(e) {
          switch (e.keyCode) {
            case 37:
                changePicture($('.carousel a.picture-prev'));
                break;
            case 39:
                changePicture($('.carousel a.picture-next'));
            break;
          }
    });

    $('.modal-body').on('mouseenter', '.carousel', function(e){
      $(this).find('.picture-action').fadeTo("fast", 0.4);
      $(this).find('.picture-prev').fadeTo("fast", 0.4);
      $(this).find('.picture-next').fadeTo("fast", 0.4);
    });

    $('.modal-body').on('mouseleave', '.carousel', function(e){
      $(this).find('.picture-action').fadeTo("fast", 0);
      $(this).find('.picture-prev').fadeTo("fast", 0);
      $(this).find('.picture-next').fadeTo("fast", 0);
    });
  }
});

