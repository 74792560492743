YP.MenuDropdown = function() {

    var actionItem = $(".js-topnav--link");
    var linkList = $(".c-yf-topnav__list");
    var linkDropdown = $('.c-yf-topnav__list--link');
    var dropdown = $(".c-yf-topnav__list--dropdown");
    var mobile = $("#js-topnav--toggle");
    var mobileParticipant   = $(".c-header.t-header_nav_participant .c-header__more");

    return {
        init: function() {

            actionItem.on("click",           this.doShowDropdown);
            mobile.on("click",               this.doShowMenuMobile);
            mobileParticipant.on("click",    this.doShowMenuMobileParticipant);

            $(window).scroll(function() {
                if ($(window).scrollTop() >= 30) {
                    $('.c-yf-topnav--pro').css({
                      'border-bottom': '1px solid #F0F2F6'
                    });
                }

                if ($(window).scrollTop() < 30){
                    $('.c-yf-topnav--pro').css({
                        border: 'none'
                    });
                }
            })
        },

        doShowDropdown: function(e){
            var itemMenuDropdown = $(this).parent();
            if(itemMenuDropdown.hasClass('is-visible')){
                itemMenuDropdown.removeClass('is-visible');
                linkDropdown.removeClass('is-hidden');
                dropdown.removeClass('is-visible');
            }
            else{
                actionItem.each(function(){
                    $(this).removeClass('is-visible');
                });
                itemMenuDropdown.addClass('is-visible');
                linkDropdown.addClass('is-hidden');
                dropdown.addClass('is-visible');
            }
        },

        doShowMenuMobile: function(e){
            e.preventDefault();
            var itemMenuDropdown2 = $(".c-yf-topnav");
            if($(this).hasClass('is-active')){
                $(this).removeClass('is-active');
                linkList.removeClass('is-active');
                $(itemMenuDropdown2).removeClass('is-active');
                $(".body-layout-pro").css({
                    'overflow': 'auto',
                    'position': 'inherit'
                });
            }

            else{
                $(this).addClass('is-active');
                linkList.addClass('is-active');
                $(itemMenuDropdown2).addClass('is-active');
                $(".body-layout-pro").css({
                    'overflow': 'hidden',
                    'position': 'fixed'
                });
            }
        },

        doShowMenuMobileParticipant: function(e){
            e.preventDefault();
            var itemMenuDropdown2 = $(".t-header_nav_participant .container_height");
            if($(itemMenuDropdown2).hasClass('is-active')){
                $(itemMenuDropdown2).removeClass('is-active');
            }
            else{
                $(itemMenuDropdown2).addClass('is-active');
            }
        }
    }
}


window.addEventListener("load", function(){
    YP.MenuDropdown().init();
});


