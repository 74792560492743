
YP.OrgaManager = function() {
    return {
        init: function() {
            $('.social-switch a').on('click', this.switchSocial);
            YP.OrgaManager().initOrgaFullEvent();
        },
        switchSocial: function(e) {
            e.preventDefault();
            block = $(this).data('block');
            $('.social-switch a').removeClass('current');
            $(this).addClass('current');
            $('.social-content .sc-block').hide();
            $('.social-content .' + block).show();

        },
        initOrgaFullEvent: function() {
            var full  = $('.content.reduce'),
                expand = $('.event-expand'),
                ot    = expand.html()
                ;
            expand.click(function(e){
                e.preventDefault();
                if(parseFloat(full.css('height')) === 700) {
                    full.animate({
                        'height': full[0].scrollHeight
                    }).css({'max-height': 'none'});
                    expand.find('a').html('Réduire');
                } else {
                    full.animate({
                        'height': 700
                    }).css({'height':  700});
                    expand.find('a').html('Voir tous les événements');
                }
            });
        }
    }

};

window.addEventListener("load", function(){
    YP.OrgaManager().init();
});
