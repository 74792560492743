YP.SlidePlaceHolder = function() {
    var input           = $(".slide-placeholder .input");

    return {
        init: function() {
            input.on("focusout",        this.placeholderFocusOut);
            input.on("focusin",         this.placeholderFocusIn);
            input.on("change",          this.placeholderChange);
            input.each(function(index){
                YP.SlidePlaceHolder().placeholderCheck($(this));
            });
        },
        placeholderFocusIn: function(e){
            if( !$(this).next(".label").hasClass("not-empty") ){
                $(this).next(".label").addClass("not-empty");
            }
        },
        placeholderFocusOut: function(e){
            if($(this).val().length === 0){
                $(this).next(".label").removeClass("not-empty");
            }
        },
        placeholderCheck: function(element){
            if($(element).val().length > 0){
                $(element).next(".label").addClass("not-empty");
            }
        },
        placeholderChange: function(e){            
            input.each(function(index){
                YP.SlidePlaceHolder().placeholderCheck($(this));
            });
        }
    }
}

window.addEventListener("load", function(){
    YP.SlidePlaceHolder().init();
});