window.YP.ProfileManager = function() {
    var profileForm = $('#profile-form');
    return {
        init: function() {
            if (profileForm.length) {
                $('#sf_guard_user_email_address').keyup(function() {
                    $('#sf_guard_user_check_password').parents().eq(1).slideDown(250);
                });
                $('#sf_guard_user_password').keyup(function() {
                    $('#sf_guard_user_password_confirmation').parents().eq(1).slideDown(250);
                });
            }
            if ($('.fb_activate').length > 0) {
                $('.fb_activate').on('ifToggled', function(e) {
                    if ($(this).prop('checked')) {
                        YP.FacebookManager().login(e);
                    } else {
                        $.get($(this).data('unlink-url'));
                        $('.og_activate').prop('disabled', 'disable').prop('checked', false);
                        notification.message = "Votre compte Facebook n\'est plus lié à votre compte Yurplan.";
                        YP.NotifManager().show(notification);
                    }
                    e.preventDefault();
                });
            }
            $('.bankForm #rib_type').on('change', function(event) {
                if ($(this).val() === "RIB") {
                    $('.bankForm .rib').show();
                    $('.bankForm .iban').hide();
                } else {
                    $('.bankForm .rib').hide();
                    $('.bankForm .iban').show();
                }
            });
            if ($('.og_activate').length > 0) {
                manageUrl = $(this).data('manage-url');
                $('.og_activate').on('ifToggled', function(e) {
                    if (!$(this).prop('checked')) {
                        $.get(manageUrl);
                    } else {
                        FB.login(function(response) {
                            if (response.authResponse) {
                                $.when(YP.FacebookManager().checkFacebookPermissions("email")).done(function(isGranted) {
                                    if (isGranted == "declined") {
                                        $(this).prop('checked', false);
                                    } else {
                                        $.get(manageUrl);
                                    }
                                });
                            } else {
                                $(this).prop('checked', false);
                            }
                        }, {
                            scope: 'publish_actions',
                        });
                    }
                });
            }
            if ($('.event-card-tickets').length) {
                YP.ProfileManager().initTicketList();
            }
            if ($('.profile-counter').length) {
                YP.ProfileManager().initCounterList();
            }
        },
        initTicketList: function() {
            $('.dl-bill button').on('click', function(e) {
                e.preventDefault();
                tableTicket = $(this).parents().eq(2).find('.table-tickets-p');
                tableTicket.slideDown(400);
                $(this).removeClass('btn-default').removeClass('dl-bill').addClass('btn-success').html('Télecharger les billets');
                $(this).unbind('click');
            });
        },
        initCounterList: function() {
            $('.profile-counter .counter a').on('click', function(e) {
                type = $(this).data('type');
                $('.profile-counter .counter a[data-type!="' + type + '"]').removeClass('active');
                $('.objects .list[data-type!="' + type + '"]').hide();
                $(this).addClass('active');
                $('.objects .list[data-type="' + type + '"]').show();
            });
        }
    }
};
window.addEventListener("load", function() {
    YP.ProfileManager().init();
});