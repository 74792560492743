var Share = {
    facebook: function(el) {
        this._open('http://www.facebook.com/sharer/sharer.php?u={0}&t={1}', true, el);
    },

    twitter: function(el) {
        this._open('http://twitter.com/intent/tweet?text={1}&url={0}&via=yurplan&related=yurplan', true, el);
    },

    linkedin: function(el) {
        this._open('https://www.linkedin.com/cws/share?url={0}&original_referer={1}&token=&isFramed=true', false, el);
    },

    _open: function(url, alernate, el) {
        var title, link ;
        if (el.data('title')) {
            title   = el.data('title') + ' sur Yurplan';
            link    = el.data('url');
        } else {
            title   = $('meta[property="og:title"]').attr('content') + ' sur Yurplan';
            link    = $('meta[property="og:url"]').attr('content');
        }
        var height  = 450,
            width   = 500,
            top = (screen.height-height) / 2,
            left = (screen.width-width) / 2;

        if (alernate) {
            window.open(url.format(php_urlencode(link), encodeURIComponent(title)), '_blank', "top="+top+",left="+left+",status=1,toolbar=0,width="+width+",height="+height);
        } else {
            window.open(url.format(php_urlencode(link), php_urlencode(link)), '_blank', "top="+top+",left="+left+",status=1,toolbar=0,width="+width+",height="+height);
        }
        return false;
    }
};

window.addEventListener("load", function(){
    $('a.chicklet-share').on('click', function(e){
        e.preventDefault();
        var el = $(this);
        if (el.hasClass('chicklet-fb')) {
            Share.facebook(el);
        }
        if (el.hasClass('chicklet-tw')) {
            Share.twitter(el);
        }
        if (el.hasClass('chicklet-linkedin')) {
            Share.linkedin(el);
        }
    });
});
