YP.AllToolsClick = function() {
    var showBtn = $(".c-yf-btn__all-tools");
    var hideBtn = $(".c-yf-btn__all-tools-remove");

    return {
        init: function() {
            showBtn.on("click", function() {
                hideBtn.show();
                showBtn.hide();
                $(".c-feature__all-tools").css({ display: "flex" });
            });

            $(".b-feature__tools").on(
                "click",
                ".c-yf-btn__all-tools-remove",
                function() {
                    hideBtn.hide();
                    showBtn.show();
                    $(".c-feature__all-tools").css({ display: "none" });
                }
            );
        }
    }
}

window.addEventListener("load", function() {
    if ($("#js-all-tools").length > 0) {
        YP.AllToolsClick().init();
    }
});
