/* eslint-disable */
YP.ModalManager = function() {
    mainModal = $('#main-modal');
    ajaxContainer = $('#ajax-container')
    notification = new Object();

    return {
        init: function(){
            preCallSuccess = function(){};
            postCallSuccess = function(){};
            $("body").on('click', 'a[data-rel=modal]', this.modalPush);
            errorBtn = $('.button_login-fb.facebook-login.facebook-login-error');
        },
        modalPush: function(e) {
            e.preventDefault();
            var href = $(this).attr('href');
            YP.ModalManager().loadModal(href);
        },
        loadModal: function(href) {
            if(mainModal.is(":visible")) {
                mainModal.addClass('waiting');
            }
            $.ajax({
                type : 'get',
                url : href,
                success : function(data) {
                    YP.ModalManager().displayModal(data);
                }
            });
        },
        displayModal: function(data) {
            mainModal.html(data);
            if(mainModal.is(":visible")) {
                mainModal.removeClass('waiting');
            } else {
                mainModal.modal('show');
            }

            form = $('#main-modal').find('form');
            preCallSuccess();
            if(form) {
                YP.ModalManager().initializeModalForm();
            }

            errorBtn = $('.facebook-login-error');
            if(errorBtn){
                YP.ModalManager().closeModalVoidFacebookCookie();
            }

            var loginModal = $("#modal__login");
            if(loginModal.length > 0){ YP.ShowPassword().init(); }

            var checkPlaceholder = $(".slide-placeholder");
            if(checkPlaceholder.length > 0){ YP.SlidePlaceHolder().init(); }

            var checkPassword = $(".check-password");
            if(checkPassword.length > 0){ YP.CheckSamePassword().init(); }

        },
        initializeModalForm: function() {
            form = $('#main-modal').find('form.form-modal');
            if(form.hasClass('no-js')) {
                return;
            }
            form.on('submit', function(e) {
                e.preventDefault();
                YP.ModalManager().modalFormSubmit();
            });

            formDl = $('#main-modal').find('form.form-modal.form-download');
            formDl.on('submit', function(e) {
                YP.ModalManager().closeModal();
            });

        },
        closeModalVoidFacebookCookie: function() {
            errorBtn.on('click', function(e) {
                YP.ModalManager().closeModal();
            });
        },
        modalFormSubmit: function() {
            text = $("#main-modal button:submit").html();
            $("#main-modal button:submit").attr('disabled', 'disabled');
            $("#main-modal button:submit").html('<i class="fa fa-spinner fa-spin"></i>');
            var options = {
                dataType: 'json',
                success:  function(data) {
                    if(data.status == "error") {
                        $("#main-modal button:submit").removeAttr('disabled');
                        $("#main-modal button:submit").html(text);
                        $("#main-modal fieldset").html(data.view);
                        preCallSuccess();
                    } else if (data.status == "redirect") {
                        window.location.href = data.url;
                    } else {
                        postCallSuccess(data);
                        mainModal.modal('hide');
                        if(data.message) {
                            notification.message = data.message;
                            notification.class= "success";
                            YP.NotifManager().show(notification);
                        }
                        if(data.view) {
                            $('.ajax-container').html(data.view);
                        } else if(data.array_elements) {
                            for(key in data.array_elements) {
                                $element = $('.ajax-container').find('#' + key);
                                $element.replaceWith(data.array_elements[key]);
                                if(data.switch_class) {
                                    $element.toggleClass(data.switch_class);
                                }
                            }
                        }
                    }

                    var loginModal = $("#modal__login");
                    if(loginModal.length > 0){ YP.ShowPassword().init(); }

                    var checkPlaceholder = $(".slide-placeholder");
                    if(checkPlaceholder.length > 0){ YP.SlidePlaceHolder().init(); }

                    var checkPassword = $(".check-password");
                    if(checkPassword.length > 0){ YP.CheckSamePassword().init(); }

                },
                error:  function(data) {
                    $('body').css('opacity', 1);
                    mainModal.modal('hide');
                    mainModal.html('');
                    notification.message = "Une erreur est survenue lors de la validation du formulaire";
                    notification.class= "error";
                    YP.NotifManager().show(notification);
                }
            };
            $('#main-modal').find('form').ajaxSubmit(options);

            return false;
        },
        setPreCallSuccess : function(call) {
            preCallSuccess = call;
        },
        unsetPreCallSuccess : function() {
            preCallSuccess = function(){};
        },
        setPostCallSuccess : function(call) {
            postCallSuccess = call;
        },
        unsetPostCallSuccess : function() {
            postCallSuccess = function(){};
        },
        closeModal : function() {
            mainModal.modal('hide');
        },
        positionModal : function() {
            margin = $(window).height() > mainModal.height() ? ($(window).height() - mainModal.height()) / 2 : 10;
            mainModal.css({'margin-top': margin, 'top' : 0});
        }
    }
};

window.addEventListener("load", function(){
    YP.ModalManager().init();
});
