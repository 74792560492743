YP.NotifManager = function() {
    return {
        show: function(settings){
            div = $('.global-notifications').addClass(settings.class);
            setTimeout(function(){
                $('span', div).html(settings.message);
                div.slideDown(200,function(){
                    YP.NotifManager().hide();
                });
            }, 500);
        },
        hide: function() {
            setTimeout(function(){
                $('.global-notifications').slideUp(200);
            }, 5000);
        },
        hasToShow: function() {
            return $('.notification-message', '.global-notifications').html() !== '';
        }
    }
};

window.addEventListener("load", function(){
    if(YP.NotifManager().hasToShow()) {
        YP.NotifManager().show({ 'class': 'notice', 'message': $('.notification-message', '.global-notifications').html()})
    }
});