window.addEventListener("load", function(){
    $('table .template-select .preview').on('click', function(e){
        var el = $(this),
            input = el.next();
        el.toggleClass("selected");

        if(input.attr("disabled")){
            input.removeAttr("disabled");
        } else {
            input.attr("disabled", "disabled");
        }

    });

    $('.block-pictures form').submit(function(e){
        var form = $(this),
            block = $(this).parent();
        $.post(
            form.attr('action'),
            form.serialize(),
            function(data){
                block.fadeOut("slow", function(e) {
                    block.remove();
                });
            }
        );
        return false;
    });

    $('ul.abuse-pictures .item .item-right .btn-abuse-submit').on('click', function(e){
        var link = $(this),
            block = $(this).parent().parent();
        $.post(
            link.attr('href'),
            function(data){
                block.fadeOut("slow", function(e) {
                    block.remove();
                });
            }
        );
        return false;
    });


    $(document).bind('reveal.facebox', function() {
    if($('#facebox .carousel').length > 0) {
        $('#facebox .popup .carousel .picture').css('height', 500);
        $('#facebox .popup .carousel .picture').css('width', 500);
        resizeCarouselPicture();
    }
    });


    });

    $(window).load(function(){
    implementPictures();
    });

    $(window).resize(function(){
    if($('#facebox .carousel').length > 0) {
    resizeCarouselPicture();
    }
    });

function implementPictures() {
    $('.show-mosaique').each(function(index) {
        var pictureBlock = $(this),
            countPicture = pictureBlock.attr('data-picture');
            var $container  = pictureBlock.children(),
                    imgs       = $container.find('img'),
                    totalImgs   = imgs.length,
                    cnt         = 0;
            imgs.each(function(i) {
                var $img    = $(this);
                $('<img/>').load(function() {
                    ++cnt;
                    if( cnt === totalImgs ) {
                        if ($container.attr('id') == "mini-container") {
                            if(countPicture && ((countPicture <= 3) || (countPicture > 5 && countPicture <= 8))) {
                                $container.montage({
                                    fixedHeight : 75,
                                    minw : 75,
                                    fillLastRow: false
                                });
                            } else {
                                $container.montage({
                                    fixedHeight : 75,
                                    minw : 75,
                                    fillLastRow: true
                                });
                            }

                        } else {
                            $container.montage({
                                fixedHeight : 100,
                                minw : 100,
                                margin : 2
                            });
                        };
                    }
                }).attr('src',$img.attr('src'));
            });
    });
}

function removeBackgroundPictures(pictureId) {
    $(".am-container a[id=" + pictureId + "]").remove();
}
