YP.CheckSamePassword = function() {
    var firstPassword       = $("#modal__sign-in #modal__sign-up__password");
    var secondPassword      = $("#modal__sign-in #modal__sign-up__password-confirmation");
    var checkPasswordBlock  = $("#modal__sign-in .check-password");

    return {
        init: function() {
            firstPassword.on("change keyup paste",  this.doCheckSamePassword);
            secondPassword.on("change keyup paste", this.doCheckSamePassword);
        },
        doCheckSamePassword: function(e){
            minimumSizeOfPassword = 6;
            if(firstPassword.val().length >= minimumSizeOfPassword || secondPassword.val().length >= minimumSizeOfPassword){
                if(firstPassword.val() === secondPassword.val()){
                    if(checkPasswordBlock.hasClass("show")){
                       checkPasswordBlock.removeClass("show");
                    }
                    checkPasswordBlock.addClass("show-2");
                }
                else{
                    if(checkPasswordBlock.hasClass("show-2")){
                        checkPasswordBlock.removeClass("show-2");
                    }
                    checkPasswordBlock.addClass("show");
                }
            }
            else{
                if(checkPasswordBlock.hasClass("show")){
                    checkPasswordBlock.removeClass("show");
                }
                if(checkPasswordBlock.hasClass("show-2")){
                    checkPasswordBlock.removeClass("show-2");
                }
            }
        }
    }
}

window.addEventListener("load", function(){
    YP.CheckSamePassword().init();
});
