import { parseJwt } from '../../app/commons/helpers/JWTHelpers';
import { getCookie } from '../../app/commons/helpers/CookiesHelpers';

const TOKEN_PARAM_NAME = 'yp-wr-token';
const CHECK_PRESENCE_PARAM_NAME = 'ypCheckPresence';

const PING_URL = `${YP_CONFIG.apiv2.url}/ping`;

// Regex for matching following event page paths:
// - /events/{eventSlug}/{eventId}
// - /events/{eventSlug}/{eventId}/tickets
// - /events/{eventSlug}/{eventId}/tickets/widget
const FIRST_FUNNEL_PAGE_REGEX = /^\/events\/(.*)\/(\d+)((\/tickets)(\/widget)?)?$/;

async function getServerTime() {
    const response = await fetch(PING_URL, { method: 'HEAD' });
    const dateHeader = response.headers.get('Date');
    if (dateHeader === null) {
        throw new Error(`Date header not present`);
    }
    return new Date(dateHeader).getTime();
}

function onExpiration() {
    const { searchParams } = new URL(window.location.href);
    searchParams.delete(TOKEN_PARAM_NAME);
    searchParams.set(CHECK_PRESENCE_PARAM_NAME, '1');
    window.location.replace(`${window.location.pathname}?${searchParams.toString()}`);
}

async function init() {
    if (!FIRST_FUNNEL_PAGE_REGEX.test(window.location.pathname)) {
        return;
    }

    const { searchParams } = new URL(window.location.href);
    let token = searchParams.get(TOKEN_PARAM_NAME);
    if (token === null) {
        token = getCookie(TOKEN_PARAM_NAME);
    }
    if (token === '') {
        return;
    }
    let jwtPayload = null;
    try {
        jwtPayload = parseJwt(token);
    } catch (e) {
        console.error(e);
    }
    if (!jwtPayload) {
        return;
    }

    let referenceTime = Date.now();
    const expirationDate = jwtPayload.exp * 1000;

    if (expirationDate - referenceTime < 30000) {
        // Token expired or about to; use a remote clock in case
        // the client clock is desynchronized
        try {
            referenceTime = await getServerTime();
            window.setTimeout(onExpiration, Math.max(0, expirationDate - referenceTime));
        } catch (e) {
            console.error('Unable to get server time', e);
        }
    } else {
        window.setTimeout(onExpiration, Math.max(0, expirationDate - referenceTime));
    }
}

window.addEventListener('load', init);
