const LOCAL_STORAGE_THEME_KEY = 'ypTheme';

const THEMES = {
    DARK: 'dark',
    DEFAULT: '',
};

const loadTheme = () => {
    const themeName = window.localStorage.getItem(LOCAL_STORAGE_THEME_KEY);
    switch (themeName) {
        case THEMES.DARK:
            document.querySelector('body').classList.add('u-theme--dark');
            break;
        case THEMES.DEFAULT:
        default:
            break;
    }
};

const saveTheme = (themeName) => {
    window.localStorage.setItem(LOCAL_STORAGE_THEME_KEY, themeName);
    loadTheme();
};

window.enableDarkTheme = saveTheme.bind(null, THEMES.DARK);

loadTheme();
