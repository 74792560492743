
YP.UploadEventPicture = function() {

    var template = '<div class="preview">'+
                        '<span class="imageHolder">'+
                            '<img />'+
                            '<span class="uploaded"></span>'+
                        '</span>'+
                        '<div class="progressHolder">'+
                            '<div class="progress"></div>'+
                        '</div>'+
                    '</div>',
        dropbox = $('#dropbox'),
        message = $('.message', dropbox),
        uploadForm = $('#eventPictureUpload');

    return {

        init: function() {

            if($("#choice_picture_manual").length > 0){
                YP.UploadEventPicture().eventUploadManual()
            }

            dropbox.filedrop({
                paramname:'picture',
                maxfiles: 8,
                maxfilesize: 2,
                queuefiles: 2,
                url: uploadForm.attr('action'),
                allowedfiletypes: ['image/jpeg','image/png','image/gif'],
                allowedfileextensions: ['.jpg','.jpeg','.png','.gif'],
                fallback_dropzoneClick: false,
                error: function(err, file) {
                    switch(err) {
                        case 'TooManyFiles':
                            notification.message = 'Vous ne pouvez ajouter que 8 images à la fois';
                            break;
                        case 'BrowserNotSupported':
                            notification.message = 'Votre naviguateur n\'est pas compatible';
                            break;
                        case 'FileTooLarge':
                           notification.message = file.name +' est trop volumineux. Le poids maximal accepté est de 2Mo';
                            break;
                        case 'FileTypeNotAllowed':
                            notification.message = 'Seules les images sont acceptées';
                        default:
                            break;
                    }
                    YP.NotifManager().show(notification);
                },

                beforeEach: function(file){
                    if(!file.type.match(/^image\//)){
                        notification.message = 'Seules les images sont acceptées';
                        YP.NotifManager().show(notification);
                        return false;
                    }
                },

                uploadStarted:function(i, file, len){
                    $('#dropbox').scrollTop($('#dropbox')[0].scrollHeight + 192);
                    YP.UploadEventPicture().createImage(file);
                },

                uploadFinished:function(i, file, response){
                    $.data(file).addClass('done');
                    $.data(file).find('.progress').parent().fadeOut();
                    $.data(file).find('.imageHolder').append('<div class="imageRemove"><a href="' + response.deleteUrl +'"><i class="fa fa-trash-o"></i></a></div>');
                },

                progressUpdated: function(i, file, progress) {
                    $.data(file).find('.progress').width(progress);
                }
            });

            dropbox.on('click', '.imageRemove a', this.removeImage);
        },
        eventUploadManual: function(){
            $("#choice_picture_manual").change(function (e) {
                var val = $(this).val()
                var file = $(this)[0].files;

                var formData = new FormData();
                formData.append('picture', file[0]);

                if(val.length != 0){
                    $.ajax({
                        type: 'POST',
                        url: uploadForm.attr('action'),
                        processData: false, 
                        contentType: false,
                        data: formData,
                        error: function(err, file) {
                            switch(err) {
                                case 'TooManyFiles':
                                    notification.message = 'Vous ne pouvez ajouter que 8 images à la fois';
                                    break;
                                case 'BrowserNotSupported':
                                    notification.message = 'Votre naviguateur n\'est pas compatible';
                                    break;
                                case 'FileTooLarge':
                                   notification.message = file.name +' est trop volumineux. Le poids maximal accepté est de 2Mo';
                                    break;
                                case 'FileTypeNotAllowed':
                                    notification.message = 'Seules les images sont acceptées';
                                default:
                                    break;
                            }
                            YP.NotifManager().show(notification);
                        },
                        success: function(response){
                            YP.UploadEventPicture().createImage(file[0]);
                            $('.progress', $('.preview').last()).parent().fadeOut();
                            $('.imageHolder', $('.preview').last()).append('<div class="imageRemove"><a href="' + response.deleteUrl +'"><i class="fa fa-trash-o"></i></a></div>');
                        }
                    });
                }
            });
        },
        createImage: function(file) {
           var preview = $(template),
                image = $('img', preview);

            var reader = new FileReader();

            image.width = 100;
            image.height = 100;

            reader.onload = function(e){
                image.attr('src',e.target.result);
            };
            reader.readAsDataURL(file);

            message.hide();
            preview.appendTo(dropbox);
            $.data(file,preview);
        },
        removeImage: function(e) {
            e.preventDefault();
            link = $(this);
            $.ajax({
                type: 'DELETE',
                url: $(this).attr('href'),
                success: function(data){
                    link.parents().eq(2).fadeOut();
                },
                error: function(data) {
                    link.parents().eq(2).fadeOut();
                }
            });
        }
    }
};

