const { $ } = window;

const CHECKBOX_PATTERN = 'check_';

window.YP.FormManager = () => ({
    init() {
        var eventForms     = $("#eventForms");
        var inputLastName  = $('input[nature="1"]').first();
        var inputFirstName = $('input[nature="2"]').first();
        var inputEmail     = $('input[nature="6"]').first();

        if(eventForms.length > 0){
            var questionInError = $(".question_error", eventForms);
            if(questionInError.length > 0){
                    $('html, body').animate({
                    scrollTop: questionInError.first().offset().top - 40
                }, 'slow');
            }
        }

        if(inputLastName.length) {
            $('#ticketRegister_last_name').on('change keyup', function(e){
                inputLastName.val($(this).val());
            });
        }
        if(inputFirstName.length) {
            $('#ticketRegister_first_name').on('change keyup', function(e){
                inputFirstName.val($(this).val());

            });
        }
        if(inputEmail.length) {
            $('#ticketRegister_email_address').on('change keyup', function(e){
                inputEmail.val($(this).val());
            });
        }

        const checkboxPatternRegExp = new RegExp(`.*${CHECKBOX_PATTERN}`);
        $('.c-form input:checkbox')
            // Only get checkbox containing the checkbox pattern
            // Not possible yet with stable CSS selector
            .filter((_, e) => checkboxPatternRegExp.test(e.id))
            .on('click', (e) => {
                const currentCheckbox = e.currentTarget;
                // Retrieve the related DOM element according the current checkbox id
                const toggledDOMClass = currentCheckbox.id.replace(checkboxPatternRegExp, 'toggle_');
                const toggledDOMElement = $(`.${toggledDOMClass}`);
                toggledDOMElement.toggle();
                // Reset the input value when the toggled element is hidden
                if (toggledDOMElement.is(':visible')) {
                    return;
                }
                if (!$(toggledDOMElement).hasClass('toggle_skip_value_edit')) {
                    // Reset the standard related input
                    toggledDOMElement.find('input:text').val(null);
                    toggledDOMElement.find('input:checkbox').val(null);
                }
                // Reset the related select2 input
                if (!currentCheckbox.dataset || !currentCheckbox.dataset.childNode) {
                    return;
                }
                const { childNode } = currentCheckbox.dataset;
                $(`#s2id_${childNode}`).select2('val', null);
            });

        function updateToggleRadio(radio) {
            const toggledDOMClass = `toggle_${radio.id.replace(`_${radio.value}`, '')}`;
            const toggledDOMElement = $(`.${toggledDOMClass}`);
            toggledDOMElement.each((index, element) => {
                const domElement = $(element);
                const domElementValues = (`${domElement.data('value')}` ?? '').split(',');
                if (domElementValues.indexOf(radio.value) > -1) {
                    domElement.show();
                } else {
                    domElement.hide();
                    if (!domElement.hasClass('toggle_skip_value_edit')) {
                        domElement.find('input:text').val(null);
                        domElement.find('input:checkbox').prop("checked", false);
                    }
                }
            });
        }
        $('.c-form input:radio').on('click', (e) => {
            updateToggleRadio(e.currentTarget);
        });

        $('.c-form input:radio').each(function updateEachToggleRadio() {
            if ($(this).prop('checked')) {
                updateToggleRadio(this);
            }
        });
        $('.c-form input:radio.enableAfterInit').removeAttr('disabled');
    },
});

window.addEventListener('load', () => window.YP.FormManager().init());
