/* eslint-disable */
/*
 * IE Number.isInteger Polyfill
 * Source: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isInteger#Polyfill
 */
const { $ } = window;

window.YP.ProductsStoreManager = () => {
    let clickPrevent = false;
    const productList = $('.cart').find('.enable').find('.cart-list-ticket-products');

    return {
        init() {
            if (!$('#ypl-store').length) return;

            this.initProductStore();
            this.initCart();
            this.initFilterBlock();

            $('#toggle-cart').on('click', this.toggleCart);

            $('form#productForms').on('submit', (e) => {
                $('.confirm-action').prop('disabled', true);
                $('.js-btn_confirm').prop('disabled', true);
            });
            $('.display-workshop-button .btn-success').on('click', (e) => {
                $('.display-workshop-button').slideUp(
                    500,
                    function () {
                        $(this).remove();
                        $('.display-workshop').slideDown(500);
                    },
                );
            });

            this.updateActionCart();
        },
        initProductStore() {
            const _self = this;
            $('.ypl-store_product_price_quantity_add').on('click', function (e) {
                const input = $(`.ypl-store_product__${$(this).data('number')} .ypl-store_product_price_input`);

                if (Math.abs(input.attr('max')) > Math.abs(input.val())) {
                    input.val(Math.abs(input.val()) + 1);
                    _self.addProductToCart(`product-${$(this).data('number')}`);
                }
            });

            $('.ypl-store_product_price_quantity_remove').on('click', function (e) {
                const input = $(`.ypl-store_product__${$(this).data('number')} .ypl-store_product_price_input`);
                if (Math.abs(input.val()) > 0) {
                    input.val(Math.abs(input.val()) - 1);
                    _self.removeProductToCart(`product-${$(this).data('number')}`);
                }
            });
        },
        addProductToCart(productTypeId) {
            const typeProductCard = $('#productForms').find(`[data-type=${productTypeId}]`).data('store');

            if (typeProductCard == 'product' && $(`.cart-list-product[data-id='${productTypeId}']`).length > 0) {
                const qty = $(`.cart-list-product[data-id='${productTypeId}'] .qty`);
                qty.text(Math.abs(qty.text()) + 1);
            } else {
                const productTemplate = $(this.getTypeProductTemplate(productTypeId));
                const productCart = productList.find(`li[data-id=${productTypeId}]`);
                if(productCart.length) {
                    productCart.removeClass('to-cancel');
                } else {
                    productList.append(productTemplate);
                }
            }
            this.updateTotalCart();
            this.updateActionCart();
        },
        removeProductToCart(productTypeId) {
            const productTemplate = productList.find(`li[data-id=${productTypeId}]`);
            const typeProductCard = $('#productForms').find(`[data-type=${productTypeId}]`).data('store');
            if (typeProductCard == 'product') {
                const qty = $('.qty', productTemplate);
                if (Math.abs(qty.text()) == 1) {
                    productTemplate.remove();
                } else {
                    qty.text(Math.abs(qty.text()) - 1);
                }
            } else {
                if(productTemplate.data('booked') === 0) {
                    productTemplate.remove();
                } else {
                    productTemplate.addClass('to-cancel');
                }
            }

            this.updateTotalCart();
            this.updateActionCart();
        },
        getTypeProductTemplate(productTypeId) {
            const currentProduct = $('#productForms').find(`[data-type=${productTypeId}]`);
            let productName = currentProduct.data('name');
            const typeProductCard = currentProduct.data('store');
            if (productName.length >= 25) {
                productName = `${jQuery.trim(productName).substring(0, 25).split(' ').slice(0, -1)
                    .join(' ')}...`;
            }

            const productAmount = currentProduct.find('.amount').html();
            let template = `<li class='cart-list-product' data-id=${productTypeId} data-booked=0`;
            template += `>`;
            template += `<span class='left' style='width: 190px'>${productName}</span>`;

            if (typeof productBegin !== 'undefined' && productBegin != 'always') {
                const beginTime = new Date(productBegin * 1000).toLocaleTimeString('fr-FR', { hour: 'numeric', minute: 'numeric' });
                const endTime = new Date(productEnd * 1000).toLocaleTimeString('fr-FR', { hour: 'numeric', minute: 'numeric' });
                const formattedTime = `${beginTime} - ${endTime}`;
                template += `<span class='left'>${formattedTime}</span>`;
            }
            if (typeProductCard == 'product') {
                template += "<span class='right product-price'>";
                template += `<span class='amount-price'>${productAmount}</span>`;
                template += " x <span class='qty'>1</span>";
                template += '</span>';
            } else {
                template += `<span class='right'>${productAmount}</span>`;
            }
            template += '</li>';
            return template;
        },
        initCart() {
            if ($('.fixedElement').length) {
                const right_column_offset = $('.fixedElement').offset().top;

                $(window).scroll(function (e) {
                    const left_column = $('.event-page .col-md-8');
                    const right_column_height = $('.fixedElement').outerHeight();
                    const window_height = $(window).height();
                    const scroll_top = $(this).scrollTop();
                    const space_bottom = 62;
                    left_column.css('minHeight', right_column_height);

                    if (right_column_height > window_height - space_bottom - right_column_offset) {
                        $('.fixedElement').removeClass('stickedtop');
                        if (scroll_top > (right_column_offset + right_column_height) - window_height + space_bottom) {
                            $('.fixedElement').addClass('sticked');
                        } else {
                            $('.fixedElement').removeClass('sticked');
                        }
                    } else {
                        $('.fixedElement').removeClass('sticked');
                        if (scroll_top > 0) {
                            $('.fixedElement').addClass('stickedtop');
                        } else {
                            $('.fixedElement').addClass('stickedtop');
                        }
                    }
                });
            }
        },
        initFilterBlock() {
            if ($('.filter_block').length) {
                $('.filter_block .filter_block_action').on('click', function (e) {
                    $('table.list-products').hide();
                    $('table.list-products tbody tr').hide();
                    if (!$(this).hasClass('selected')) {
                        $('.filter_block .filter_block_action').removeClass('selected');
                        $(this).addClass('selected');
                        let productIds = $(this).data('indexes').toString().split(',');
                        productIds.forEach((productId, index) => {
                            if (index == 0) {
                                $(`table.list-products tr[data-type='${productId}']`).parent().parent().show();
                            }
                            $(`table.list-products tr[data-type='${productId}']`).show();
                        }, this);
                    } else {
                        $(this).removeClass('selected');
                    }
                });
            }
        },
        updateTotalCart() {
            let total = 0;

            $('.cart-list').find('.right').each(function () {
                if ($(this).text().length) {
                    const value = $(this).text().trim();
                    if ($(this).hasClass('product-price')) {
                        productPrice = $(this).find('.amount-price').text().trim();
                        productQty = $(this).find('.qty').text().trim();

                        if ($.isNumeric(productPrice) && $.isNumeric(productQty)) {
                            total += parseFloat(productPrice * productQty);
                        }
                    } else if ($.isNumeric(value)) {
                        total += parseFloat($(this).text());
                    }
                }
            });

            $('.cart-total .amount').html(total.toFixed(2));
        },
        updateActionCart() {
            // Selected products or no product available
            const isValid = productList.find('li:not(.disabled)').length > 0
            || productList.find('li.to-cancel').length > 0
            || (
                $('.list-products tbody tr').lenght
                && $('.list-products tbody tr:not(.u-workshop_disabled)').length <= 0
            )
            if (!isValid) {
                $('.js-btn_next').show();
                $('.js-btn_confirm').hide();
            } else {
                $('.js-btn_next').hide();
                $('.js-btn_confirm').show();
            }
        },
        toggleCart() {
            if ($('.cart-list').is(':visible')) {
                $('#toggle-cart .fa').addClass('fa-sort-asc');
                $('#toggle-cart .fa').removeClass('fa-sort-desc');
            } else {
                $('#toggle-cart .fa').addClass('fa-sort-desc');
                $('#toggle-cart .fa').removeClass('fa-sort-asc');
            }

            $('.cart-list').toggle();
        },
    };
};

window.addEventListener('load', () => {
    YP.ProductsStoreManager().init();
});
