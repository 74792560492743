YP.TestimonialsToggleClick = function() {
    $('.js-toggle_1').click(function() {
        $('.js-toggle_content_text_1').slideDown(200);
        $('.js-toggle_content_1, .js-toggle_1').addClass('active');
        $('.js-toggle_content_text_2, .js-toggle_content_text_3, .js-toggle_content_text_4, .js-toggle_content_text_5, .js-toggle_content_text_6').slideUp(200);
        $('.js-toggle_content_2, .js-toggle_content_3, .js-toggle_content_4, .js-toggle_content_5, .js-toggle_content_6, .js-toggle_2, .js-toggle_3, .js-toggle_4, .js-toggle_5, .js-toggle_6').removeClass('active');

    })
    $('.js-toggle_2').click(function() {
        $('.js-toggle_content_text_2').slideDown(200);
        $('.js-toggle_content_2, .js-toggle_2').addClass('active');
        $('.js-toggle_content_text_1, .js-toggle_content_text_3, .js-toggle_content_text_4, .js-toggle_content_text_5, .js-toggle_content_text_6').slideUp(200);
        $('.js-toggle_content_1, .js-toggle_content_3, .js-toggle_content_4, .js-toggle_content_5, .js-toggle_content_6, .js-toggle_1, .js-toggle_3, .js-toggle_4, .js-toggle_5, .js-toggle_6').removeClass('active');
    })
    $('.js-toggle_3').click(function() {
        $('.js-toggle_content_text_3').slideDown(200);
        $('.js-toggle_content_3, .js-toggle_3').addClass('active');
        $('.js-toggle_content_text_1, .js-toggle_content_text_2, .js-toggle_content_text_4, .js-toggle_content_text_5, .js-toggle_content_text_6').slideUp(200);
        $('.js-toggle_content_1, .js-toggle_content_2, .js-toggle_content_4, .js-toggle_content_5, .js-toggle_content_6, .js-toggle_1, .js-toggle_2, .js-toggle_4, .js-toggle_5, .js-toggle_6').removeClass('active');
    })
    $('.js-toggle_4').click(function() {
        $('.js-toggle_content_text_4').slideDown(200);
        $('.js-toggle_content_4, .js-toggle_4').addClass('active');
        $('.js-toggle_content_text_1, .js-toggle_content_text_2, .js-toggle_content_text_3, .js-toggle_content_text_5, .js-toggle_content_text_6').slideUp(200);
        $('.js-toggle_content_1, .js-toggle_content_2, .js-toggle_content_3, .js-toggle_content_5, .js-toggle_content_6, .js-toggle_1, .js-toggle_2, .js-toggle_3, .js-toggle_5, .js-toggle_6').removeClass('active');
    })
    $('.js-toggle_5').click(function() {
        $('.js-toggle_content_text_5').slideDown(200);
        $('.js-toggle_content_5, .js-toggle_5').addClass('active');
        $('.js-toggle_content_text_1, .js-toggle_content_text_2, .js-toggle_content_text_3, .js-toggle_content_text_4, .js-toggle_content_text_6').slideUp(200);
        $('.js-toggle_content_1, .js-toggle_content_2, .js-toggle_content_3, .js-toggle_content_4, .js-toggle_content_6, .js-toggle_1, .js-toggle_2, .js-toggle_3, .js-toggle_4, .js-toggle_6').removeClass('active');
    })
    $('.js-toggle_6').click(function() {
        $('.js-toggle_content_text_6').slideDown(200);
        $('.js-toggle_content_6, .js-toggle_6').addClass('active');
        $('.js-toggle_content_text_1, .js-toggle_content_text_2, .js-toggle_content_text_3, .js-toggle_content_text_4 , .js-toggle_content_text_5').slideUp(200);
        $('.js-toggle_content_1, .js-toggle_content_2, .js-toggle_content_3, .js-toggle_content_4, .js-toggle_content_5, .js-toggle_1, .js-toggle_2, .js-toggle_3, .js-toggle_4, .js-toggle_5').removeClass('active');
    })
}

window.addEventListener("load", function() {
    if ($("#accordion").length > 0) {
        YP.TestimonialsToggleClick();
    }
});