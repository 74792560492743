const { $ } = window;

window.YP.PaymentManager = function PaymentManager() {
    const paymentForm = $('#payment-form');
    const paymentTypeChoice = $('input[name="paymentForm[payment_type_choice]"]');
    let alreadySubmited = false;
    let hfields = null;

    const PAYMENT_TYPE_CB = '1';
    const PAYMENT_TYPE_DELAYED = '10';
    const PAYMENT_TYPE_ALMA = '23';

    return {
        init() {
            if (paymentForm.length > 0) {
                // Sélectionner par défaut la première option de paiement
                paymentTypeChoice.first().prop('checked', true);
                paymentTypeChoice.first().trigger('change');
                this.initPaymentForm();
                this.handlePaymentFormSubmit();
            }
        },
        initPaymentForm() {
            const style = {
                input: {
                    'font-size': '16px',
                    color: '#171717',
                    'line-height': '45px',
                    'background-color': 'transparent',
                },
            };
            hfields = window.dalenys.hostedFields({
                key: {
                    id: $('.cb-form').data('api-key-id'),
                    value: $('.cb-form').data('api-key'),
                },
                fields: {
                    brand: {
                        id: 'brand-container',
                        useInlineSelection: true,
                        isCbPreferredNetwork: true,
                    },
                    card: {
                        id: 'card-container',
                        style,
                        enableAutospacing: true,
                    },
                    expiry: {
                        id: 'expiry-container',
                        style,

                    },
                    cryptogram: {
                        id: 'cvv-container',
                        style,
                        onFocus() {
                            $('.ccv-info').show();
                        },
                        onBlur() {
                            $('.ccv-info').hide();
                        },
                    },
                },
            });
            hfields.load();
        },
        handlePaymentFormSubmit() {
            paymentForm.on('submit', function handlePaymentFormSubmit(e) {
                const theForm = this;
                e.preventDefault();

                if (alreadySubmited) {
                    return e.preventDefault();
                }
                alreadySubmited = true;
                const btn = $(this).find('.c-btn');
                const oldVal = btn.val();
                btn.addClass('c-btn--loading');
                btn.attr('disabled', 'disabled');

                switch (paymentTypeChoice.filter(':checked').val()) {
                    case PAYMENT_TYPE_CB:
                        hfields.createToken((result) => {
                            if (result.execCode === '0000') {
                                document.getElementById('paymentForm_Cb_hf_token').value = result.hfToken;
                                document.getElementById('paymentForm_Cb_card_code').value = result.cardCode;
                                document.getElementById('paymentForm_Cb_card_expiry_date').value = result.cardValidityDate;
                                document.getElementById('paymentForm_Cb_card_type').value = result.cardType;
                                document.getElementById('paymentForm_Cb_card_selected_brand').value = result.selectedBrand;
                                theForm.submit();
                                return true;
                            }
                            btn.removeClass('c-btn--loading');
                            btn.removeAttr('disabled');
                            btn.text(oldVal);
                            alreadySubmited = false;
                            return false;
                        });
                        break;

                    case PAYMENT_TYPE_ALMA:
                        const almaPaymentUrl = $('#alma-in-page').data('payment-url');
                        const formData = new FormData(theForm);
                        theForm.action = almaPaymentUrl;
                        fetch(almaPaymentUrl, {
                            method: 'POST',
                            body: formData,
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                if (data.success) {
                                    inPage.startPayment({ paymentId: data.paymentId });
                                } else {
                                    btn.removeClass('c-btn--loading');
                                    btn.removeAttr('disabled');
                                    btn.text(oldVal);
                                    alreadySubmited = false;
                                    alert('Une erreur est survenue lors de l\'initialisation du paiement. Veuillez réessayer.');
                                }
                            })
                            .catch((err) => {
                                debugger;
                                btn.removeClass('c-btn--loading');
                                btn.removeAttr('disabled');
                                btn.text(oldVal);
                                alreadySubmited = false;
                                alert('Une erreur est survenue lors de la communication avec le serveur. Veuillez réessayer.');
                            });
                        break;
                    case PAYMENT_TYPE_DELAYED:
                        theForm.submit();
                        break;
                    default:
                        alert('Moyen de paiement non autorisé');
                        break;
                }
                return false;
            });
        },
    };
};

window.addEventListener("load", function() {
    window.YP.PaymentManager().init();
});
