YP.VideoPlayClick = function () {

    return {
        init: function () {
            $('#videoThumbail').on('click', function (e) {
                $("#videoContainer").css('display', 'block');
                $("#videoThumbail").css('display', 'none');
            });
            $('#videoThumbail').on('click', function (f) {

                $("#videoPlayer")[0].src += "&autoplay=1";
                ev.preventDefault();

            });
        }
    }
}

window.addEventListener("load", function () {
    if ($('#videoThumbail').length > 0) {
        YP.VideoPlayClick().init();
    }
});