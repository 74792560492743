/* eslint-disable */
// Exemple :
// <div>
//    <input id="my-input" class="toggle_checkbox" data-target="#my-content" />
//    <div id="my-content">
//         Element to toggle here
//    </div>
// </div>

const CHECKBOX_CLASS = 'toggle_checkbox';

function toggleChildOnCheckboxClick() {
    $(`.${CHECKBOX_CLASS}`).each(function() {
        if($(this).prop('checked')) {
            const toggledDOMClass = $(this).data('target');
            $(`${toggledDOMClass}`).toggle();
        }
    });
    $(`.${CHECKBOX_CLASS}`).on('click', (e) => {
        // Retrieve the related DOM element according the current checkbox data-attribute
        const toggledDOMClass = $(e.currentTarget).data('target');
        $(`${toggledDOMClass}`).toggle();
    });
}

window.addEventListener("load", toggleChildOnCheckboxClick);
