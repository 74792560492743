
YP.EventManager = function() {
    var desc                    = $('.event-description');
    var btnAlreadyOrdered       = $("#lost-order #already-ordered");
    var formAlreadyOrdered      = $("#form-already-ordered");
    var btnSubmitAlreadyOrdered = $("#submit-already-ordered", formAlreadyOrdered);
    var messageAjaxError        = $("#field_email_address .help-block", formAlreadyOrdered);

    return {
        init: function() {
            YP.EventManager().initEventDescription();
            YP.EventManager().initAlreadyOrdered();

            if($(".layout_tab").length > 0){

                this.tabEvent();
            }

            if($(".workshops").length > 0){
                this.loadModalInfoWorkshop();
            }

            $('.object-admin-design-btn').click(function(e){
                $('.show_design_panel').toggle();
                e.preventDefault();
            });
        },
        initEventDescription: function() {
            if(desc.length > 0) {
                var full  = $('.event-descripton-full'),
                    expand = $('.event-description-expand'),
                    ot    = expand.html()
                    ;

                height = (parseFloat(full.css('height')) + 20) +'px';

                if(parseFloat(height) < 100) {
                    expand.hide();
                } else {
                    expand.click(function(e){
                        e.preventDefault();
                        if(desc.css('height') === height) {
                            desc.animate({'height': '105px'});
                            expand.html(ot);
                        } else {
                            desc.animate({
                                'height': height
                            }).css({'max-height': 'none'});
                            $('#event-description-down').addClass('hide');
                            $('#event-description-up').removeClass('hide');
                        }
                    });
                }
            }
        },
        loadModalInfoWorkshop : function(){
            $('.get-info-modal').on('click', function(e) {
                productTypeId = $(this).data('id');
                href = $(this).data('show-url') + '?id=' + productTypeId;
                YP.ModalManager().loadModal(href);
            });
        },
        tabEvent : function(){
            $(".onglets li").on("click", function(e){
                e.preventDefault();
                if(!$(this).hasClass("active")){
                    $('.sub-cover').show();
                    e.preventDefault();
                    $(".onglets li").removeClass("active");
                    $(this).addClass("active");
                    $(".tab.active").slideUp().removeClass("active");
                    $(".tab-" + $(this).index()).addClass("active").slideDown();
                }
            });
        },
        initAlreadyOrdered : function(){

            if(btnAlreadyOrdered.length > 0 && btnSubmitAlreadyOrdered.length > 0){

                var _isWidget = formAlreadyOrdered.data("widget");


                btnAlreadyOrdered.on("click", function(e){
                    e.preventDefault();
                    formAlreadyOrdered.slideToggle();
                })

                if(_isWidget){


                    formAlreadyOrdered.keypress('keyup keypress', function(e) {
                        var code = e.keyCode || e.which;
                        if (code == 13) {
                            e.preventDefault();
                            return false;
                        }
                    });
                }

                btnSubmitAlreadyOrdered.on("click", function(e){
                    e.preventDefault();

                    var _text     = btnSubmitAlreadyOrdered.html();


                    btnSubmitAlreadyOrdered.attr('disabled', 'disabled');
                    btnSubmitAlreadyOrdered.html('<i class="fa fa-spinner fa-spin"></i>');

                    $.post(
                        formAlreadyOrdered.attr("action"),
                        formAlreadyOrdered.serialize(),
                        function success(data){

                            if(data.status == "valid"){

                                $("#lost-order").after("<p id='result-lost-order'>" + data.messages + "</p>");

                                formAlreadyOrdered.slideToggle();
                                setTimeout(function(){
                                    $("#result-lost-order").remove();
                                }, 8000);
                            }
                            else if(typeof data.messages.email_address != "undefined"){

                                messageAjaxError.text(data.messages.email_address);
                                setTimeout(function(){
                                    messageAjaxError.text("");
                                }, 5000);
                            }

                        },
                        'json'
                    )
                    .fail(function(data){

                        messageAjaxError.text("Une erreur s'est produite");
                        setTimeout(function(){
                            messageAjaxError.text("");
                        }, 5000);

                    })
                    .always(function(){
                        btnSubmitAlreadyOrdered.removeAttr('disabled');
                        btnSubmitAlreadyOrdered.html(_text);

                    });
                })
            }
        }
    }

};

window.addEventListener("load", function(){
    YP.EventManager().init();
});
