YP.sliderUnivers = function() {

   var slider                   = $(".slider_yp");
   var slider_top               = $(".slider_yp .top");
   var slider_element           = $(".slider_yp .slide");
   var prev                     = $(".slider_yp .prev");
   var next                     = $(".slider_yp .next");
   var logo                     = $(".slider_yp .logo");
   var break_point              = $(".slider_yp .breakpoint");
   var more_responive           = $(".slider_yp .slider__more");

    return {
        init: function() { 

            prev.on( "click", {  number: -1         },   this.slide );
            next.on( "click", {  number: 1          },   this.slide );
            logo.on( "click", {  number: "logo"     },   this.slide );
            more_responive.on( "click",  this.showMore);
            this.height();
            this.resize();

        },
        resize: function() {

            $( window ).resize(function() {
                setTimeout(function(){  
                    YP.sliderUnivers().height();
                }, 100);
            });

        },
        height: function() {

            if( $(break_point).css("display") == "none" ){

                YP.sliderUnivers().slideClass(1);

                var max_height = 0;
                slider_element.each(function( index ) {
                    var height = $(this).outerHeight();
                    if(max_height < height){
                        max_height = height;
                    };
                });
                slider_top.height(max_height);
            }
            else{
                slider_top.height("auto");
            }

        },
        slide: function( event ) {           

            if( $(break_point).css("display") == "none" ){
                if( event.data.number == "logo"){
                    var number_next = $(this).data("number");
                }
                else{
                    var number = $(".slider_yp .top .slide_active").data("number");
                    var number_next = number + (event.data.number);
                    if( number_next < 1 ){
                        number_next = slider_element.length;
                    }
                    else if ( number_next > slider_element.length ){
                        number_next = 1;
                    }
                }
                YP.sliderUnivers().slideClass(number_next)
            }

        },
        slideClass: function(number_next){

            $(slider).find(".slide_active").removeClass("slide_active");
            $(slider).find("[data-number="+number_next+"]").addClass("slide_active");

        },
        showMore: function( event ){

            $(this).addClass("yp_hide");
            $(slider_element).each(function(){
                $(this).addClass("slide_active");
            });

        }
    }
}

window.addEventListener("load", function(){
    YP.sliderUnivers().init();
});
