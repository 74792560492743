/* global YP YURPLAN_CHART CHART_KEY $ _ MANUAL_PLACEMENT AUTOMATIC_PLACEMENT PUBLIC_KEY seatsio */
YP.Chart = () => {
    let chart = null;
    let chartCart = null;
    let seats = null;
    let globalQuantity = 0;
    let scope = null;
    let ticketTable = null;
    let ticketItems = null;
    let ticketSubmitBtn = null;
    let automaticSubmitBtn = null;
    let orderForm = null;
    let bestAvailableInitiated = false;
    let promoSeatingChartContent = $('.promo-seating-chart');

    const SCOPES = {
        FRONTEND: 'frontend',
        TICKETOFFICE: 'ticketoffice',
        PRO: 'pro',
    };
    let seatsCart = [];

    return {
        init() {
            chart = $('#chart');
            chartCart = $('#chart_cart');
            if (chart.length <= 0 || YURPLAN_CHART === undefined || !CHART_KEY) {
                return;
            }
            this.initScopeVar();
            this.loadChartDetail();
            if ($('.apply-promocode').length > 0) {
                $('.apply-promocode').removeClass('apply-promocode').addClass('promocode-seating');
            }
            if (scope === SCOPES.TICKETOFFICE) {
                // TODO find a way to remove this
                $(document).on('click touchend', '.reset-basket-btn', this.clearSelectedObject.bind(this));
            } else if (scope === SCOPES.PRO) {
                $(document).on('click touchend', '.js-reset-order', this.clearSelectedObject.bind(this));
            }
            chartCart.on('click touchend', '.js-seat-remove, .basked-minus-seating', function handleSeatRemove(e) {
                e.preventDefault();
                const seatId = $(this).data('seat-id');
                const seatData = seatsCart.find(seat => (
                    seatId === seat.id
                ));
                if (!seatData) return;
                let seatPayload = null;
                if (seatData.seat === seatData.seatId) {
                    seatPayload = [{
                        id: seatData.seat,
                        ticketType: seatData.typeTicketName,
                    }];
                } else {
                    seatPayload = [seatData.seatId];
                }
                seats.deselectObjects(seatPayload);
            });
        },
        initScopeVar() {
            scope = chart.data('scope');
            switch (scope) {
                case SCOPES.PRO:
                    ticketTable = $('#add-ticket-choice');
                    ticketItems = $('select.nbSeat');
                    ticketSubmitBtn = $('#js-confirm-addsell');
                    automaticSubmitBtn = $('#js-confirm-automatic');
                    orderForm = $('#print-table-ticket');
                    break;
                case SCOPES.TICKETOFFICE:
                    ticketTable = $('#print-ticket-office');
                    ticketItems = $('.cart-item');
                    ticketSubmitBtn = $('.new-order');
                    automaticSubmitBtn = $('.automatic-order');
                    orderForm = $('#order_form');
                    break;
                default:
                    ticketTable = $('#print-table-ticket');
                    ticketItems = $('select.nbSeat');
                    ticketSubmitBtn = $('#booking-tickets');
                    automaticSubmitBtn = $('#booking-automatic');
                    orderForm = $('#print-table-ticket');
                    break;
            }
        },
        loadChartDetail() {
            const loadOptions = {};
            let typeSeatsChoiceValue = 'on_plan';
            if (!MANUAL_PLACEMENT) {
                loadOptions.readOnly = true;
                typeSeatsChoiceValue = 'best_available';
            }
            this.loadChart(loadOptions);
            orderForm.append(
                `<input type='hidden' id='type_seats_choice' name='type_seats_choice' value='${typeSeatsChoiceValue}' />`,
            );

            if (AUTOMATIC_PLACEMENT && MANUAL_PLACEMENT) {
                this.initTabChart();
            }
        },
        loadChart(additionalParams = {}) {
            if (seats) {
                seats.destroy();
            }
            const params = {};
            if (additionalParams.readOnly) {
                params.onHoldSucceeded = this.onAutomaticOnHoldSucceeded.bind(this);
            } else {
                params.onObjectSelected = this.onDefaultObjectSelected.bind(this);
                params.onObjectDeselected = this.onDefaultObjectDeselected.bind(this);
            }

            if (additionalParams.readOnly) {
                $('#chart_categories').hide();
                promoSeatingChartContent.remove();
                ticketTable.show();
                ticketSubmitBtn.hide();
                automaticSubmitBtn.show();
                this.loadBestAvailableChoice();
                this.initBestAvailableForm();
            } else {
                $('#chart').show();
                $('#chart_categories').show();
                $('#chart_cart').show();
                ticketTable.hide();
                ticketSubmitBtn.show();
                automaticSubmitBtn.hide();
                this.removeBestAvailableChoice();
                this.initTicketofficeCategories();
            }

            const config = {
                divId: 'chart',
                publicKey: PUBLIC_KEY,
                onHoldFailed: this.onHoldFailed,
                mode: additionalParams.readOnly ? 'static' : 'normal',
                ...params,
                ...additionalParams,
                ...YURPLAN_CHART,
                priceFormatter: function(price) {
                    return price + ' €';
                },
            };

            seats = new seatsio.SeatingChart(config).render();
        },
        initTabChart() {
            $('.seating__choice').show();
            const self = this;
            $('.seating__choice').on('click', function toggleActiveTabChart(e) {
                e.preventDefault();
                if ($(this).hasClass('seating__choice_form')) {
                    self.loadAutomaticChart();
                    $('.seating__choice_chart').removeClass('is-active');
                    $('.seating__choice_form').addClass('is-active');
                } else {
                    self.loadManualChart();
                    $('.seating__choice_chart').addClass('is-active');
                    $('.seating__choice_form').removeClass('is-active');
                }
                $('#js-form-addsell').hide();
            });
        },
        loadAutomaticChart() {
            promoSeatingChartContent.remove();
            seats.clearSelection(this.loadChart.bind(this, {
                readOnly: true,
            }));
        },
        loadManualChart(clear = true) {
            promoSeatingChartContent.appendTo('#chart_cart .table');
            const loadChart = this.loadChart.bind(this, {
                readOnly: false,
            });

            if (!clear) {
                loadChart();
                return;
            }
            if (scope === SCOPES.TICKETOFFICE) {
                YP.Cart = [];
                this.refreshCart();
            }
            seats.clearSelection(loadChart);
        },
        loadBestAvailableChoice() {
            $('#type_seats_choice').val('best_available');

            // @TODO Refactor this using seatsCart
            if (scope === SCOPES.TICKETOFFICE) {
                $('.basked-minus-seating', document).trigger('click');
            } else {
                $('#chart_cart table tbody tr .remove').trigger('click');
                $('select.nbSeat').trigger('change');
            }
        },
        removeBestAvailableChoice() {
            $('#type_seats_choice').val('on_plan');

            // @TODO Refactor this using seatsCart
            if (chart.hasClass('ticketoffice')) {
                $('.basked-minus', document).trigger('click');
            } else {
                ticketItems.val(0);
            }
        },
        onHoldFailed() {
            /* eslint-disable-next-line no-alert */
            alert('Une erreur est survenue lors de votre tentative de réservation. Votre page va être rechargée.');
            window.location.reload();
        },
        buildSeatData(object, selectedTicketType) {
            const { section } = object.labels;
            const row = object.labels.parent;
            const seat = object.labels.own;
            const { seatId } = object;
            return {
                id: Math.random().toString(32).slice(2),
                seatId,
                section: section || null,
                row: row || null,
                seat: seat || null,
                typeTicketId: Number.parseInt(selectedTicketType.id, 10),
                typeTicketPrice: Number.parseFloat(selectedTicketType.price),
                typeTicketName: selectedTicketType.ticketType,
            };
        },
        onDefaultObjectSelected(...args) {
            const seatData = this.buildSeatData.call(this, ...args);
            seatsCart = seatsCart.concat([seatData]);
            switch (scope) {
                case SCOPES.TICKETOFFICE:
                    return this.onObjectSelectedVSP.call(this, seatData);
                case SCOPES.PRO:
                    return this.onObjectSelectedPro.call(this, seatData);
                default:
                    return this.onObjectSelected.call(this, seatData);
            }
        },
        onDefaultObjectDeselected(object, selectedTicketType) {
            const newSeatData = this.buildSeatData.call(this, object, selectedTicketType);
            const seatData = seatsCart.find(seat => (
                seat.seatId === newSeatData.seatId
             && seat.typeTicketId === newSeatData.typeTicketId
            ));
            if (!seatData) return null;
            seatsCart = seatsCart.filter(seat => seat.id !== seatData.id);
            switch (scope) {
                case SCOPES.TICKETOFFICE:
                    return this.onObjectDeselectedVSP.call(this, seatData);
                case SCOPES.PRO:
                    return this.onObjectDeselectedPro.call(this, seatData);
                default:
                    return this.onObjectDeselected.call(this, seatData);
            }
        },
        onObjectSelected(seatData) {
            $('tbody', chartCart).append(
                `<tr id="seat-${seatData.id}">
                    <td class="c-table__cell u-text-align--center">${(seatData.section || ' - ')}</td>
                    <td class="c-table__cell u-text-align--center">${(seatData.row || ' - ')}</td>
                    <td class="c-table__cell u-text-align--center">${(seatData.seat || ' - ')}</td>
                    <td class="c-table__cell u-text-align--center">${seatData.typeTicketName}</td>
                    <td class="c-table__cell u-text-align--center">${seatData.typeTicketPrice}€</td>
                    <td class="c-table__cell c-table__cell--action">
                        <button
                            class="c-btn c-btn--tertiary c-btn--square js-seat-remove"
                            data-seat-id="${seatData.id}"
                        >
                            <i class="yp_font yp_font_trash-2"></i>
                        </button>
                    </td>
                </tr>`,
            );
            chartCart.append(
                `<input
                    type='hidden'
                    data-seat-uuid='${seatData.id}'
                    name='seats[]'
                />`,
            );
            // Use jQuery to avoid JSON characters escape
            $(`input[data-seat-uuid=${seatData.id}]`).val(JSON.stringify(seatData));

            const countTypeTicketSelected = $(`select[name='typeTicket[${seatData.typeTicketId}]']`).val();
            const newCountTypeTicketSelected = Number.parseInt(countTypeTicketSelected, 10) + 1;

            $(`select[name='typeTicket[${seatData.typeTicketId}]']`).val(newCountTypeTicketSelected);

            $('select.nbSeat').trigger('change');
        },
        onObjectSelectedVSP(seatData) {
            YP.Cart.push({
                id: seatData.typeTicketId,
                uuid: seatData.id,
                seat: seatData.seatId,
                qqt: 1,
                price: seatData.typeTicketPrice,
                title: seatData.typeTicketName,
            });
            YP.Chart().refreshCart();

            $('#order_form').append(
                `<input
                    type='hidden'
                    data-seat-uuid='${seatData.id}'
                    name='seats[]'
                />`,
            );
            // Use jQuery to avoid JSON characters escape
            $(`input[data-seat-uuid=${seatData.id}]`).val(JSON.stringify(seatData));
        },
        onObjectSelectedPro(seatData) {
            $('#chart_cart tbody').append(
                `<tr id="seat-${seatData.id}" class="c-table__row">
                    <td class="c-table__cell">${(seatData.section || ' - ')}</td>
                    <td class="c-table__cell">${(seatData.row || ' - ')}</td>
                    <td class="c-table__cell">${(seatData.seat || ' - ')}</td>
                    <td class="c-table__cell">${seatData.typeTicketName}</td>
                    <td class="c-table__cell">${seatData.typeTicketPrice} euros</td>
                    <td class="c-table__cell">
                        <div
                            class="s-iconaction s-iconaction_delete js-seat-remove"
                            data-seat-id="${seatData.id}"
                        >
                            <i class="fa fa-times s-iconaction__icon"></i>
                        </div>
                    </td>
                </tr>`,
            );

            $('form.addsell').append(
                `<input
                    type='hidden'
                    data-seat-uuid='${seatData.id}'
                    name='seats[]'
                />`,
            );
            // Use jQuery to avoid JSON characters escape
            $(`input[data-seat-uuid=${seatData.id}]`).val(JSON.stringify(seatData));

            const countTypeTicketSelected = $(`select[name='typeTicket[${seatData.typeTicketId}]']`).val();
            const newCountTypeTicketSelected = Number.parseInt(countTypeTicketSelected, 10) + 1;

            $(`select[name='typeTicket[${seatData.typeTicketId}]']`).val(newCountTypeTicketSelected);
            document.getElementById('js-confirm-addsell').removeAttribute('disabled');
        },
        onObjectDeselected(seatData) {
            $(`#seat-${seatData.id}`, chartCart).remove();
            $(`input[data-seat-uuid=${seatData.id}]`, chartCart).remove();

            const countTypeTicketSelected = $(`select[name='typeTicket[${seatData.typeTicketId}]']`).val();
            const newCountTypeTicketSelected = Number.parseInt(countTypeTicketSelected, 10) - 1;

            $(`select[name='typeTicket[${seatData.typeTicketId}]']`).val(newCountTypeTicketSelected);

            ticketItems.trigger('change');
        },
        onObjectDeselectedVSP(seatData) {
            const newCart = YP.Cart.filter(cartItem => (
                cartItem.uuid !== seatData.id
            ));
            if (newCart.length === YP.Cart) {
                return;
            }
            YP.Cart = newCart;
            YP.Chart().refreshCart();
            $(`#order_form input[data-seat-uuid=${seatData.id}]`).remove();
        },
        onObjectDeselectedPro(seatData) {
            $(`#seat-${seatData.id}`, $('form.addsell')).remove();
            $(`input[data-seat-uuid=${seatData.id}]`, $('form.addsell')).remove();

            const countTypeTicketSelected = $(`select[name='typeTicket[${seatData.typeTicketId}]']`).val();
            const newCountTypeTicketSelected = Number.parseInt(countTypeTicketSelected, 10) - 1;

            $(`select[name='typeTicket[${seatData.typeTicketId}]']`).val(newCountTypeTicketSelected);
            $('#js-form-addsell').hide();
        },
        onAutomaticOnHoldSucceeded() {
            if (globalQuantity <= 0 || seats.selectedSeats.length !== globalQuantity) {
                return;
            }
            globalQuantity = 0;
            this.loadManualChart(false);
            ticketSubmitBtn.prop('disabled', false);
        },
        clearSelectedObject() {
            if ($('.seating__choice_form').hasClass('is-active')) {
                $('.category-type-tickets').hide();
                $('#categories-list').show();
                this.loadAutomaticChart(true);
            }
            seats.clearSelection();
        },
        refreshCart() {
            const table = $('.basket-pan').find('table');

            const template = _.template($('#cart-item-seat').html());
            let html = '';
            let total = 0;
            const typeTickets = {};

            YP.Cart.forEach((cartItem) => {
                total += (cartItem.qqt * cartItem.price);
                if (!cartItem.uuid) return;
                html += template(cartItem);
                if (!typeTickets[cartItem.id]) {
                    typeTickets[cartItem.id] = 0;
                }

                typeTickets[cartItem.id] += cartItem.qqt;
            });
            $(table).html(html);

            if (YP.Cart.length > 0) {
                $('.basket-btn').removeClass('no_ticket');
                $('.cart-total').html(`(${total}€)`);
            } else {
                $('.basket-btn').addClass('no_ticket');
                $('.cart-total').html('');
            }

            $('#typeTickets').val(JSON.stringify(typeTickets));
        },
        initBestAvailableForm() {
            if (bestAvailableInitiated) return;
            automaticSubmitBtn.on('click', (e) => {
                e.preventDefault();
                e.stopPropagation();
                seats.clearSelection(() => {
                    const bestAvailableMap = {};
                    ticketItems = $(ticketItems.selector);
                    ticketItems.each(function computeGlobalQuantity() {
                        let quantity = 0;
                        if (scope === SCOPES.TICKETOFFICE) {
                            quantity = Number.parseInt($(this).data('qqt'), 10);
                        } else {
                            quantity = Number.parseInt($(this).val(), 10);
                        }
                        if (quantity <= 0) {
                            return;
                        }
                        const categoryId = Number.parseInt($(this).data('category'), 10);
                        const typeTicketName = $(this).data('name');
                        bestAvailableMap[categoryId] = bestAvailableMap[categoryId] || {};
                        bestAvailableMap[categoryId][typeTicketName] = quantity;
                        globalQuantity += quantity;
                    });
                    Object.keys(bestAvailableMap).forEach((category) => {
                        const params = {
                            category,
                            ticketTypes: bestAvailableMap[category],
                            clearSelection: false,
                        };
                        seats.selectBestAvailable(params);
                    });
                    window.scrollTo(0, 0);
                });
            });
            bestAvailableInitiated = true;
        },
        initTicketofficeCategories() {
            $('.category-pan').on('click', function toggleTicketofficeCategories(e) {
                e.preventDefault();
                const categoryId = $(this).attr('id');
                $('#categories-list').hide();
                $('.category-type-tickets').hide();
                $(`.category-type-tickets[data-id=${categoryId}]`).show();
            });

            $('.category-type-tickets-nav a.reset').on('click', (e) => {
                e.preventDefault();
                $('.category-type-tickets').hide();
                $('#categories-list').show();
                YP.Cart = [];
                YP.Chart().refreshCart();
            });
        },
        hideChart() {
            $('.print_ticket_tab__seating iframe').css('visibility', 'hidden');
            $('#chart div').show();
        },
        displayChart() {
            $('.print_ticket_tab__seating iframe').css('visibility', 'visible');
            $('#chart div').hide();
        },
    };
};

window.addEventListener('load', () => {
    const chart = $('#chart');
    // No chart
    if (chart.length <= 0) return;
    // Defer load for ticketoffice
    if (chart.data('scope') === 'ticketoffice') return;
    YP.Chart().init();
});
