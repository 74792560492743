
YP.ContactManager = function() {
    var titleSupport = $('.title-tab');

    return {
        init: function() {
            titleSupport.on("click", function(e){
                e.preventDefault();

                YP.ContactManager().removeClass(".title-support", "active");
                $(this).toggleClass("active");

                var content = $(this).next().html();
                
                var display = $(".content-support-display");
                display.html(content);
                if (!display.is(":visible")) {
                    display.show();
                }
            });

            $('#btn-faq').click(function(e) {
                e.preventDefault();
                
                YP.ContactManager().removeClass(".title-support", "active");
                $(this).toggleClass("active");

                var display = $(".content-support-display");
                display.html();
                if (display.is(":visible")) {
                    display.hide();
                }

                HS.beacon.toggle();
            });
           
        },
        removeClass: function(target, classToRemove) {
            $(target).each(function(index) {
                $(this).removeClass(classToRemove);
            });
        }
    }

};

window.addEventListener("load", function(){
    YP.ContactManager().init();
});

