window.addEventListener("load", function(){

    var coverForm = $('.uploadCover');
    if (coverForm.length > 0) {
        initUploadForm(coverForm);
    }

});

function initUploadForm (coverForm) {
    $('#fileupload-cover').fileupload({
        dataType: 'json',
        url: coverForm.data('url'),
        send: function (e, data) {
            $('.fileinput-button', $('.uploadCover')).append($('<i class="fa fa-cog fa-spin loading-cover"></i>'))
        },
        add: function(e, data) {
            data.submit();
        },
        done: function (e, data) {
            $('.loading-cover').remove();
            if(data.result.error != false) {
                notification.message = data.result.error ;
                notification.class= "success";
                YP.NotifManager().show(notification);
                return false;
            }
            var coverName = data.result.cover;
            $('.cover').replaceWith(data.result.html);
            var img = $(".cover img");
            img.load(function(){
                $('.loading-cover').remove();
                var coverFormBis = $('.uploadCover'),
                maskWidth  = img.width(),
                maskHeight = coverFormBis.height(),
                imgPos     = img.offset(),
                imgWidth   = img.width(),
                imgHeight  = img.height(),
                x1 = (imgPos.left + maskWidth) - imgWidth,
                y1 = (imgPos.top + maskHeight) - imgHeight,
                x2 = imgPos.left,
                y2 = imgPos.top;
                img.draggable({ containment: [x1,y1,x2,y2] });
                img.css({cursor: 'move'});
                initUploadForm(coverFormBis);

                $('.btn.saveCover').click(function(e) {
                    e.preventDefault();
                    coverHeight = document.getElementById('preview').naturalHeight;
                    heightRatio = coverHeight / imgHeight;
                    coverPos = -parseFloat($('#preview').css('top'));
                    $.post($(this).data('url'), {
                        'top': coverPos * heightRatio,
                        'cover': coverName
                    }, function(data) {
                        $('.cover').replaceWith(data.html);
                        coverForm = $('.uploadCover');
                        d = new Date();
                        $(".cover-img img").attr("src", $(".cover-img img").attr("src")+"?timestamp="+d.getTime());
                    });
                });
            });
        }
    });
}
