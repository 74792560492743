const { $ } = window;

window.initDatePicker = function initDatePicker() {
    $.fn.datepicker.dates.fr = {
        days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        daysShort: ['dim.', 'lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.'],
        daysMin: ['dim.', 'lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.'],
        months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        monthsShort: ['Janv.', 'Févr.', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil.', 'Août', 'Sept.', 'Oct.', 'Nov.', 'Déc.'],
        today: 'Aujourd\'hui',
    };
    const defaultOptions = {
        format: 'dd/mm/yyyy',
        language: 'fr',
        weekStart: 1,
        autoclose: true,
    };

    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 1);

    $('.input-calendar, .input-calendar-with-past').each(function initCalendar() {
        const id = `${$(this).attr('name')}_datepicker-container`.replace(/[^\w\s]/gi, '');
        $(this).parent().append(`<div id="${id}" class="js-datepicker-container"></div>`);
        $(this).datepicker({
            ...defaultOptions,
            container: `#${id}`,
            startDate: $(this).hasClass('.input-datepicker-with-past') ? startDate : undefined,
        });
    });
    $('.timepicker').each(function initTimepicker() {
        const id = `${$(this).attr('name')}_timepicker-container`.replace(/[^\w\s]/gi, '');
        $(this).parent().append(`<div id="${id}" class="js-timepicker-container"></div>`);
        $(this).timePicker({
            step: 15,
            endTime: new Date(0, 0, 0, 23, 50, 0),
            container: `#${id}`,
        });
    });
};
