YP.ShowPassword = function() {
    var blockPassword   = $("#modal__login .input_password");
    var linkPassword    = $("#modal__login .show-password");

    return {
        init: function() {
            linkPassword.on("click",    this.doShowPassword);
        },
        doShowPassword: function(e){
            e.preventDefault();
            if( $(this).hasClass("show") ){
                $(this).removeClass("show");
                blockPassword.attr('type','password');
            }
            else{
                $(this).addClass("show");
                blockPassword.attr('type','text');
            }
            var value = blockPassword.val();
            blockPassword.focus().val('').val(value);
        }
    }
}

window.addEventListener("load", function(){
    YP.ShowPassword().init();
});


